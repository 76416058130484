import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
} from "reactstrap";
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from "react-router-dom";

const MedicalPortfolioP = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
  const [medicationHistory, setMedicationHistory] = useState([]);
  const [gnnResult, setGnnResult] = useState(null);
  const [appointmentsHistoryData, setAppointmentsHistoryData] = useState(false);
  const [appointmentHistory, setAppointmentHistory] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const medResponse = await fetch(`https://lifelinecare.somee.com/api/medicine/${userProfile.pId}`);
        if (medResponse.ok) {
          const medData = await medResponse.json();
          const medicationData = medData.prescriptions || [];
          setAppointmentsHistoryData(medicationData.length > 0);
          setMedicationHistory(medicationData);
        } else {
          console.error("Failed to fetch medication history:", medResponse.status, medResponse.statusText);
        }


// Fetch appointment history
const appointmentResponse = await fetch(`https://lifelinecare.somee.com/api/appointment_cr/AppointmentHistory?a_email=${userProfile.pEmail}`);
if (appointmentResponse.ok) {
  const appointmentData = await appointmentResponse.json();
  setAppointmentHistory(appointmentData);
} else {
  console.error("Failed to fetch appointment history:", appointmentResponse.status, appointmentResponse.statusText);
}




        // Fetch GNN result
        const gnnResponse = await fetch(`https://lifelinecare.somee.com/api/GNNModel/${userProfile.pId}`);
        if (gnnResponse.ok) {
          const gnnData = await gnnResponse.json();
          setGnnResult(gnnData || { GNNResult: "No data available" });
        } else {
          console.error("Failed to fetch GNN result:", gnnResponse.status, gnnResponse.statusText);
          setGnnResult({ GNNResult: "No data available" });
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }


 


    };

    fetchData();
  }, [userProfile.pId]);

  const gnnChartData = {
    labels: ['No Serious Health Risk', 'Serious Health Risk'],
    datasets: [
      {
        data: [
          gnnResult && gnnResult.GNNResult === 'No serious health risk detected.' ? 1 : 0,
          gnnResult && gnnResult.GNNResult !== 'No serious health risk detected.' ? 1 : 0,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(255, 99, 132, 0.6)',
        ],
      },
    ],
  };

  const userProfileHeaders = [
    { key: 'pName', pname: 'Patient Name' },
    { key: 'pDob', pname: 'Date of Birth' },
    { key: 'pMobile', pname: 'Mobile' },
    { key: 'pEmail', pname: 'Email' },
    // Add more headers as needed
  ];

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };
  const formatTimeAMPM = (timeString) => {
    const date = new Date(`2000-01-01T${timeString}`);
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };
  return (
    <div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Patient Information</CardTitle>
        </CardHeader>
        <CardBody>
          <Table>
            <tbody>
              {userProfileHeaders.map(({ key, pname }) => (
                <tr key={key}>
                  <th>{pname}</th>
                  <td>{key === 'pDob' ? formatDate(userProfile[key]) : userProfile[key]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle tag="h4">Doctor Prescription History</CardTitle>
        </CardHeader>

        <CardBody>
          {medicationHistory ? (
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th>Doctor</th>
                    <th>Hospital</th>
                    <th>Date</th>
                    <th>Disease</th>
                    <th>Medicine</th>
                    <th>Schedule Time</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Notes</th> {/* Add Notes header */}
                  </tr>
                </thead>
                <tbody>
                  {gnnResult && gnnResult.Prescriptions.map((prescription, index) => (
                    <tr key={index}>
                      <td>{prescription.doctor.dName}</td>
                      <td>{prescription.hospital.hName}</td>
                      <td>{formatDate(prescription.prescription.dpDate)}</td>
                      <td>{prescription.prescription.dpDisease}</td>
                      <td>{prescription.prescription.dpMedicine}</td>
                      <td>{prescription.prescription.dpScheduleTime}</td>
                      <td>{formatDate(prescription.prescription.dpStartDate)}</td>
                      <td>{formatDate(prescription.prescription.dpEndDate)}</td>
                      <td>{prescription.prescription.dpNotes}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <h4>No appointment history found.</h4>
          )}
        </CardBody>
        <br />
      </Card>


      <Card>
        <CardHeader>
          <CardTitle tag="h4">Appointment History</CardTitle>
        </CardHeader>

        <CardBody>
          {appointmentHistory.length > 0 ? (
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                  
                    <th>Appointment Date</th>
                    <th>Appointment Time</th>
                    <th>Status</th>
                    <th>Reason</th>
                  
                  </tr>
                </thead>
                <tbody>
                  {appointmentHistory.map((appointment, index) => (
                    <tr key={index}>
                      <td>{formatDate(appointment.aDate)}</td>
                      <td>{formatTimeAMPM(appointment.aTime)}</td>
                      <td>{appointment.aType}</td>
                      <td>{appointment.aReason}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <h4>No appointment history found.</h4>
          )}
        </CardBody>
        <br />
      </Card>



      <Card>
        <CardBody>
          {gnnResult && gnnResult.GNNResult === 'No serious health risk detected.' ? (
            <>
              <h2 style={{ textAlign: 'center' }}>Result on your past medical history <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M6 9l6 6 6-6" />
              </svg></h2><br />
              <h3 style={{ textAlign: 'center' }}>Congratulations!</h3>
            </>
          ) : (
            <>
              <h3 style={{ textAlign: 'center' }}>Oh, Consult a doctor please.</h3>
              <p style={{ textAlign: 'center' }}>{gnnResult && gnnResult.GNNResult}</p>
            </>
          )}
          <div style={{ maxWidth: '300px', margin: 'auto' }}>
            <Doughnut data={gnnChartData} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default MedicalPortfolioP;

import React, { useState, useEffect } from "react";
import {Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import defaultProfileIcon from "../assets/admin.png"; 

import {
  Button,
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Input,
} from "reactstrap";

const userProfile = JSON.parse(localStorage.getItem("userProfile")) || null;

const DoctorH = () => {
  const [doctors, setDoctors] = useState([]);
  const [searchDoctor, setSearchDoctor] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const userProfile = JSON.parse(localStorage.getItem("userProfile")) || null;

    if (!userProfile) {
      navigate('/admin/Login');
    } else {
      fetchDoctors(userProfile.hId);
    }
  }, [navigate]);
  const fetchDoctors = async (hospitalId) => {
    try {
      const response = await fetch(`https://lifelinecare.somee.com/api/Doctors_Cr/ByHospitalId/${hospitalId}`);
      if (response.ok) {
        const data = await response.json();
        setDoctors(data);
      } else {
        console.error("Failed to fetch doctors");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderDoctorImage = (doctor) => {
    if (doctor.dImage) {
      return (
        <img
          src={`https://lifelinecare.somee.com/images_d/${doctor.dImage}`}
          alt="Doctor"
          width={50}
          height={50}
        />
      );
    } else {
      return (
        <img
        src={defaultProfileIcon} 
          alt="Profile Icon"
          width={50}
          height={50}
        />
      );
    }
  };

  const handleStatusChange = async (doctorId, newStatus) => {
    try {
      const response = await fetch(`https://lifelinecare.somee.com/api/Doctors_Cr/${doctorId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ dAvailablityStatus: newStatus })
      });
      if (response.ok) {
        // Update the local state or fetch the updated data
        fetchDoctors(userProfile.hId);
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const filteredDoctors = doctors.filter(doctor =>
    doctor.dName.toLowerCase().includes(searchDoctor.toLowerCase())
  );

  return (
    <div className="content">
      <Row className="justify-content-end">
        <Col xs="12">
          <Link to="/admin/AdddoctorH">
            <Button className="btn-fill" color="primary" type="submit">
              New Doctor
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Col xs="12">
          <Input
            type="text"
            placeholder="Search by Doctor Name"
            value={searchDoctor}
            onChange={(e) => setSearchDoctor(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader>
              <h5 className="title">Doctor List</h5>
            </CardHeader>
            <div className="table-responsive">
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Field</th>
                    <th>Availability</th>
                    <th>Image</th>
                    <th>Action</th> {/* Add a new column for action */}
                  </tr>
                </thead>
                <tbody>
                  {filteredDoctors.map((doctor) => (
                    <tr key={doctor.dId}>
                      <td>{doctor.dName}</td>
                      <td>{doctor.dEmail}</td>
                      <td>{doctor.dField}</td>
                      <td>{doctor.dAvailablityStatus}</td>
                      <td>{renderDoctorImage(doctor)}</td>
                      <td>
                        <Button
                          color={doctor.dAvailablityStatus === 'Available' ? 'success' : 'danger'}
                          onClick={() => handleStatusChange(doctor.dId, doctor.dAvailablityStatus === 'Available' ? 'Unavailable' : 'Available')}
                        >
                          {doctor.dAvailablityStatus === 'Available' ? 'Make Unavailable' : 'Make Available'}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {filteredDoctors.length === 0 && (
              <div className="text-center p-3 text-white">
                No records found.
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DoctorH;

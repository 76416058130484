import React, { useState, useEffect } from "react";
import { Link} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {
  Table,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
} from "reactstrap";
import Swal from 'sweetalert2';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 15];

export default function BloodE() {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [bloodData, setBloodData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sortOption, setSortOption] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [alert, setAlert] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const storedUserProfile = localStorage.getItem('userProfile');
    const parsedUserProfile = storedUserProfile ? JSON.parse(storedUserProfile) : null;

    if (!parsedUserProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSortOptionChange = (option) => {
    if (sortOption === option) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortOption(option);
      setSortOrder("asc");
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = async () => {
    try {
      const storedUserProfile = localStorage.getItem('userProfile');
      const parsedUserProfile = storedUserProfile ? JSON.parse(storedUserProfile) : null;

      if (parsedUserProfile && parsedUserProfile.heHId) {
        const response = await fetch(
          `https://lifelinecare.somee.com/api/BloodAvailability_Cr/api/BloodAvailability/${parsedUserProfile.heHId}`
        );
        setLoading(false);
        if (response.ok) {
          const data = await response.json();
          setBloodData(data);
          setLoading(true);
        } else {
          console.error(
            "Failed to fetch blood bottles data:",
            response.status,
            response.statusText
          );
        }
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching blood bottles data:",
        error
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const indexOfLastPatient = currentPage * rowsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - rowsPerPage;

  const filteredBloodData = bloodData.filter((patient) =>
    patient.baBloodGroup.toLowerCase().includes(searchText.toLowerCase())
  );

  const sortedBloodData = sortOption
    ? filteredBloodData.sort((a, b) => {
        if (sortOption === "baBloodGroup") {
          const valueA =
            typeof a[sortOption] === "string"
              ? a[sortOption].toLowerCase()
              : String(a[sortOption]);
          const valueB =
            typeof b[sortOption] === "string"
              ? b[sortOption].toLowerCase()
              : String(b[sortOption]);
          return sortOrder === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else if (sortOption === "baBottlesAvailable") {
          const valueA = parseInt(a[sortOption]);
          const valueB = parseInt(b[sortOption]);
          return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
        }
      })
    : filteredBloodData;

  const currentPatients = sortedBloodData.slice(
    indexOfFirstPatient,
    indexOfLastPatient
  );

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDelete = async (baId) => {
    const confirmation = await Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    });

    if (confirmation.isConfirmed) {
      try {
        const response = await fetch(`https://lifelinecare.somee.com/api/BloodAvailability_Cr/${baId}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          Swal.fire(
            'Success',
            'Record deleted successfully!',
            'success'
          );
          fetchData();
        } else {
          Swal.fire(
            'Error deleting record',
            'Unknown error',
            'error'
          );
          console.error('Failed to delete blood availability:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('An error occurred while deleting blood availability:', error);
      }
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Input
            type="text"
            style={{ height: "70px", fontSize: "15px" }}
            placeholder="Search by blood group..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <br></br>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag="h4">Blood Availability</CardTitle>
                </Col>
                <Col className="text-center">
                  <Link to="/employee/AddBloodE">
                    <button
                      className="btn btn-success"
                    >
                      Add
                    </button>
                  </Link>
                </Col>
                <Col className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      className="btn-icon"
                      color="link"
                      data-toggle="dropdown"
                      type="button"
                    >
                      <i className="tim-icons icon-settings-gear-63" />
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                      <DropdownItem
                        onClick={() => handleSortOptionChange("baBloodGroup")}
                      >
                        Sort By Blood Group{" "}
                        {sortOption === "baBloodGroup" &&
                          (sortOrder === "asc" ? "▲" : "▼")}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleSortOptionChange("baBottlesAvailable")
                        }
                      >
                        Sort By Bottles Available{" "}
                        {sortOption === "baBottlesAvailable" &&
                          (sortOrder === "asc" ? "▲" : "▼")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {filteredBloodData.length === 0 ? (
                <p>No results match the search criteria.</p>
              ) : (
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>BloodGroup</th>
                      <th>Bottles Available</th>
                      <th>Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPatients.map((patient, index) => (
                      <tr key={index}>
                        <td>
                          <p>{patient.baBloodGroup}</p>
                        </td>
                        <td>
                          <p>{patient.baBottlesAvailable}</p>
                        </td>
                        <td>
                          <p>{formatDate(patient.baDate)}</p>
                        </td>
                        <td>
                          <button
                            className="btn btn-warning"
                            onClick={() => handleDelete(patient.baId)}
                          >
                            Out
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>

            <CardFooter>
              <Row>
                <Col className="pr-md-1" md="3">
                  <span>Rows Per Page:</span>
                  <select
                    className="form-control"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    {ROWS_PER_PAGE_OPTIONS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md="6" className="d-flex justify-content-center">
                  <Pagination style={{ marginTop: "40px" }}>
                    {Array.from(
                      {
                        length: Math.ceil(sortedBloodData.length / rowsPerPage),
                      },
                      (_, i) => i + 1
                    ).map((number) => (
                      <PaginationItem
                        key={number}
                        active={number === currentPage}
                      >
                        <PaginationLink
                          onClick={() => paginate(number)}
                          href="#"
                        >
                          {number}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                  </Pagination>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

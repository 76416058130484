import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

const AddPatientE = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  const userProfileData = JSON.parse(localStorage.getItem("userProfile")) || null;
  const [formData, setFormData] = useState({
    pdId: "",
    pName: "",
    pDob: "",
    pMobile: "",
    pTime: "",
    pEmail: "",
    pReason: "",
  });
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(null);

  const showAlert = (title, message, type) => {
    setAlert(
      <SweetAlert
        title={title}
        onConfirm={() => setAlert(null)}
        timeout={3000}
        type={type}
      >
        {message}
      </SweetAlert>
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "pTime") {
      const formattedTime = value + ":00";
      setFormData({ ...formData, [name]: formattedTime });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setSubmitting(true);

      const response = await fetch("https://lifelinecare.somee.com/api/patient_cr", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const responseData = await response.json();
        showAlert("Success", "Patient Added!", "success");
        setFormData({
          pdId: "",
          pName: "",
          pDob: "",
          pMobile: "",
          pEmail: "",
          pReason: "",
        });
      } else {
        const responseData = await response.text();
      if (response.status === 403 && responseData.includes("Patient with this Email already exists!")) {
        showAlert("Error", "Patient with this Email already exists!", "error");
      } else {
        showAlert("Error", `Error adding patient: ${responseData}`, "error");
      }
      }
    } catch (error) {
      showAlert("Error", `Error adding patient: ${error.message}`, "error");
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!userProfileData || !userProfileData.heHId) {
      console.error("Invalid userProfile or hospitalId");
      return;
    }

    setLoading(true);
    fetch(`https://lifelinecare.somee.com/api/Doctors_Cr/ByHospitalId/${userProfileData.heHId}`)
      .then((response) => response.json())
      .then((data) => {
        setDoctors(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching doctors:", error);
        setLoading(false);
      });
  }, [userProfileData]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Add Patient</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Doctor</label>
                      <Input
                        value={formData.pdId}
                        onChange={handleChange}
                        name="pdId"
                        type="select"
                      >
                        <option value="" disabled>
                          Select Doctor
                        </option>
                        {doctors.map((doctor) => (
                          <option key={doctor.dId} value={doctor.dId}>
                            {doctor.dName}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <label>Name</label>
                      <Input
                        value={formData.pName}
                        onChange={handleChange}
                        name="pName"
                        placeholder="Full Name"
                        type="text"
                        minLength={3}
                        maxLength={25}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="4">
                    <FormGroup>
                      <label>Date Of Birth</label>
                      <Input
                        value={formData.pDob}
                        onChange={handleChange}
                        name="pDob"
                        placeholder="DOB"
                        type="date"
                        required
                        max={new Date().toISOString().split("T")[0]}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Contact No.</label>
                      <Input
                        onChange={handleChange}
                        name="pMobile"
                        value={formData.pMobile}
                        placeholder="3132166045"
                        type="tel"
                        required
                        pattern="^3[0-9]{9}$"
                        maxLength={10}
                        minLength={10}
                      />
                    </FormGroup>
                  </Col>

                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        value={formData.pEmail}
                        onChange={handleChange}
                        name="pEmail"
                        placeholder="example@gmail.com"
                        type="email"
                        required
                        minLength={5}
                        maxLength={50}
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="4">
                    <FormGroup>
                      <label>Admit Time</label>
                      <Input
                        value={formData.pTime}
                        onChange={handleChange}
                        name="pTime"
                        type="time"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Reason</label>
                      <Input
                        value={formData.pReason}
                        onChange={handleChange}
                        name="pReason"
                        placeholder="Health Issue"
                        type="textarea"
                        required
                        minLength={10}
                        maxLength={150}
                      />
                    </FormGroup>
                  </Col>

                  <Col className="pl-md-1" md="6">
                    <FormGroup></FormGroup>
                  </Col>
                </Row>
                <Button
                  className="btn-fill"
                  color="success"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? "Submitting..." : "Save"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert}
    </div>
  );
};

export default AddPatientE;

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const EmergencyE = () => {
  const navigate = useNavigate(); // Initialize navigate
  const userProfile = JSON.parse(localStorage.getItem("userProfile")) || null;

  const [formData, setFormData] = useState({
    ecHId: userProfile ? userProfile.heHId : "",
    ecNumber1: "",
    ecNumber2: "",
  });
  const [loading, setLoading] = useState(false); // Add loading state
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [editingId, setEditingId] = useState(null); // Add state for editing ID
  const [alert, setAlert] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://lifelinecare.somee.com/api/EmergencyContact_cr/services/${userProfile.heHId}`
      );
      if (response.ok) {
        const data = await response.json();
        setEmergencyContacts(data);
      } else {
        setEmergencyContacts([]);
        console.error(
          "Failed to fetch emergency contacts:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching emergency contacts:",
        error
      );
    }
  };

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');
    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, [userProfile.heHId]);

  const handleEdit = (contactId) => {
    const contactToEdit = emergencyContacts.find(contact => contact.ecId === contactId);
    if (contactToEdit) {
      setFormData({
        ecId: contactToEdit.ecId,
        ecHId: contactToEdit.ecHId,
        ecNumber1: contactToEdit.ecNumber1,
        ecNumber2: contactToEdit.ecNumber2
      });
      setEditingId(contactId);
    } else {
      console.error("Contact not found for editing");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submit button is clicked

    const phoneNumberPattern = /^(\+92|0)\d{10}$/;
    if (!phoneNumberPattern.test(formData.ecNumber1)) {
      showAlert(
        "Error",
        "Please enter a valid phone number in the format +92XXXXXXXXXX or 0XXXXXXXXXX for Emergency No. 1",
        "error"
      );
      setLoading(false); // Set loading to false
      return; // Exit early if phone number format is invalid
    }

    // Validate phone number format for ecNumber2
    if (!phoneNumberPattern.test(formData.ecNumber2)) {
      showAlert(
        "Error",
        "Please enter a valid phone number in the format +92XXXXXXXXXX or 0XXXXXXXXXX for Emergency No. 2",
        "error"
      );
      setLoading(false); // Set loading to false
      return; // Exit early if phone number format is invalid
    }
    try {
      const response = await fetch(
        "https://lifelinecare.somee.com/api/EmergencyContact_cr",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        showAlert(
          "Success",
          "Emergency Contact's added successfully!",
          "success"
        );
        setFormData({
          ecHId: userProfile ? userProfile.heHId : "",
          ecNumber1: "",
          ecNumber2: "",
        });
      } else {
        showAlert(
          "Error",
          "Only two emergency contacts can be added, kindly edit from below if needed.",
          response.statusText,
          "error"
        );
      }
    } catch (error) {
      showAlert("Error", "Error adding emergency contact:", error, "error");
    } finally {
      setLoading(false); // Set loading to false after API call completes
    }
  };

  const handleSaveEdit = async () => {
    try {
      const response = await fetch(
        `https://lifelinecare.somee.com/api/EmergencyContact_cr?id=${editingId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setFormData({
          ecHId: userProfile ? userProfile.heHId : "",
          ecNumber1: "",
          ecNumber2: "",
        });
        setEditingId(null);
        fetchData(); // Refresh emergency contacts after edit
        showAlert(
          "Success",
          "Emergency Contact updated successfully!",
          "success"
        );
      } else {
        showAlert(
          "Error",
          "Error updating emergency contact:",
          response.statusText,
          "error"
        );
      }
    } catch (error) {
      showAlert("Error", "Error updating emergency contact:", error, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const renderEmergencyContacts = () => {
    return emergencyContacts.map((contact) => (
      <tr key={contact.ecId}>
        <td>
          {editingId === contact.ecId ? (
            <Input
              name="ecNumber1"
              value={formData.ecNumber1}
              onChange={handleChange}
              placeholder="Contact"
              type="text"
              required
              minLength={11}
              maxLength={13}
            />
          ) : (
            contact.ecNumber1
          )}
        </td>
        <td>
          {editingId === contact.ecId ? (
            <Input
              name="ecNumber2"
              value={formData.ecNumber2}
              onChange={handleChange}
              placeholder="Contact"
              type="text"
              required
              minLength={11}
              maxLength={13}
            />
          ) : (
            contact.ecNumber2
          )}
        </td>
        <td>
          {editingId === contact.ecId ? (
            <Button
              color="success"
              size="sm"
              onClick={handleSaveEdit}
            >
              Save
            </Button>
          ) : (
            <Button
              color="info"
              size="sm"
              onClick={() => handleEdit(contact.ecId)}
            >
              Edit
            </Button>
          )}
        </td>
      </tr>
    ));
  };


  const showAlert = (title, message, type) => {
    setAlert(
      <SweetAlert
        title={title}
        onConfirm={() => setAlert(null)}
        timeout={3000}
        type={type}
      >
        {message}
      </SweetAlert>
    );
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Add Emergency Contact</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Hospital ID</label>
                      <Input
                        value={formData.ecHId}
                        readOnly
                        placeholder=""
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Emergency No. 1</label>
                      <Input
                        onChange={handleChange}
                        name="ecNumber1"
                        value={formData.ecNumber1}
                        placeholder="Contact"
                        type="text"
                        required
                        minLength={11}
                        maxLength={13}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Emergency No. 2</label>
                      <Input
                        onChange={handleChange}
                        name="ecNumber2"
                        value={formData.ecNumber2}
                        placeholder="Contact"
                        type="text"
                        required
                        minLength={11}
                        maxLength={13}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  className="btn-fill"
                  color="success"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Emergency Contact Numbers</h5>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Emergency No. 1</th>
                    <th>Emergency No. 2</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {renderEmergencyContacts()}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert}
    </div>
  );
};

export default EmergencyE;

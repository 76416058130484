import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, CardBody, CardFooter, FormGroup, Form, Input, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from 'react-router-dom';
const PrescriptionD = () => {
  const navigate = useNavigate(); // Add useNavigate hook
  const userProfile = JSON.parse(localStorage.getItem('userProfile')) || null; // Define userProfile variable

  useEffect(() => {
    if (!userProfile) {
      navigate('/admin/Login'); // Redirect to login page if userProfile is not available
    }
  }, [navigate, userProfile]);

  const { pId } = useParams();
  const [loading, setLoading] = useState(false);
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [alert, setAlert] = useState(null);

  const [prescriptionData, setPrescriptionData] = useState({
    dpDisease: "", // Initialize to empty string
    dpStartDate: "",
    dpEndDate: "",
    dpScheduleTime: "",
    dpMedicineName: "",
  });

  const [doctorNotes, setDoctorNotes] = useState("");
  useEffect(() => {
    // Check if there are existing prescriptions
    if (prescriptionList.length > 0) {
      // Set prescription data to the last item in the list
      setPrescriptionData(prescriptionList[prescriptionList.length - 1]);
    }
  }, [prescriptionList]);

  const timeOptions = [ // Define timeOptions variable
    { value: "Before Breakfast", label: "Before Breakfast" },
    { value: "After Breakfast", label: "After Breakfast" },
    { value: "Before Lunch", label: "Before Lunch" },
    { value: "After Lunch", label: "After Lunch" },
    { value: "Before Dinner", label: "Before Dinner" },
    { value: "After Dinner", label: "After Dinner" },
  ];

  const showAlert = (title, message, type) => {
    setAlert(
      <SweetAlert
        title={title}
        onConfirm={() => {
          setAlert(null);
        }}
        timeout={3000}
        type={type}
      >
        {message}
      </SweetAlert>
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      for (const prescriptionItem of prescriptionList) {
        const response = await fetch("https://lifelinecare.somee.com/api/medicine", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dpDId: userProfile.dId,
            dpPId: pId,
            dpDate: new Date().toISOString().slice(0, 10), // Today's date
            dpDisease: prescriptionItem.dpDisease,
            dpMedicine: prescriptionItem.dpMedicineName,
            dpScheduleTime: prescriptionItem.dpScheduleTime,
            dpStartDate: prescriptionItem.dpStartDate,
            dpEndDate: prescriptionItem.dpEndDate,
            dpNotes: doctorNotes,
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to add prescription: ${response.status} ${response.statusText}`);
        }
      }

      showAlert("Success", "Prescription(s) added successfully!", "success");

      setPrescriptionList([]);
      setDoctorNotes("");
    } catch (error) {
      showAlert("Error", `An error occurred while adding prescription: ${error}`, "error");
    } finally {
      setLoading(false);
    }
  };

  const addPrescription = () => {
    if (!prescriptionData.dpDisease || !prescriptionData.dpMedicineName || !prescriptionData.dpStartDate || !prescriptionData.dpEndDate || !prescriptionData.dpScheduleTime) {
      showAlert("Error", "Please fill in all required fields.", "error");
      return;
    }

    setPrescriptionList([...prescriptionList, prescriptionData]);

    setPrescriptionData({
      dpDisease: "",
      dpMedicineName: "",
      dpStartDate: "",
      dpEndDate: "",
      dpScheduleTime: "",
    });

    showAlert("Success", "Prescription added successfully!", "success");
  };

  // Function to handle editing a prescription item
  const handleEditPrescription = (index) => {
    const editedPrescription = prescriptionList[index];
    setPrescriptionData({ ...editedPrescription });
    // Remove the edited prescription from the list
    const updatedPrescriptions = prescriptionList.filter((_, i) => i !== index);
    setPrescriptionList(updatedPrescriptions);
  };

  // Function to handle deleting a prescription item
  const handleDeletePrescription = (index) => {
    // Remove the prescription item at the specified index
    const updatedPrescriptions = [...prescriptionList];
    updatedPrescriptions.splice(index, 1);
    setPrescriptionList(updatedPrescriptions);
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Add Prescription</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Disease</label>
                      <Input
                        value={prescriptionData.dpDisease}
                        name="dpDisease"
                        placeholder="Disease"
                        type="text"
                        onChange={(e) => setPrescriptionData({ ...prescriptionData, dpDisease: e.target.value })}
                        required
                        maxLength={50}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Medicine</label>
                      <Input
                        value={prescriptionData.dpMedicineName}
                        name="dpMedicineName"
                        placeholder="Medicine Name"
                        type="text"
                        onChange={(e) => setPrescriptionData({ ...prescriptionData, dpMedicineName: e.target.value })}
                        required
                        maxLength={50}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Select Start Date</label>
                      <Input
                        value={prescriptionData.dpStartDate}
                        name="dpStartDate"
                        type="date"
                        onChange={(e) => setPrescriptionData({ ...prescriptionData, dpStartDate: e.target.value })}
                        required
                        min={new Date().toISOString().split('T')[0]}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Select End Date</label>
                      <Input
                        value={prescriptionData.dpEndDate}
                        name="dpEndDate"
                        type="date"
                        min={new Date().toISOString().split('T')[0]}
                        onChange={(e) => setPrescriptionData({ ...prescriptionData, dpEndDate: e.target.value })}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Schedule Time</label>
                      <select
                        className="form-control"
                        name="dpScheduleTime"
                        onChange={(e) => setPrescriptionData({ ...prescriptionData, dpScheduleTime: e.target.value })}
                        required
                        value={prescriptionData.dpScheduleTime}
                      >
                        <option value="">Select Time</option>
                        {timeOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Doctor's Notes</label>
                      <Input
                        value={doctorNotes}
                        name="doctorNotes"
                        placeholder="Doctor's Notes"
                        type="textarea"
                        onChange={(e) => setDoctorNotes(e.target.value)}
                        maxLength={500}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter>
              <Button className="btn-fill" color="primary" onClick={addPrescription} disabled={loading}>
                Add Prescription
              </Button>
              <Button className="btn-fill" color="primary" onClick={handleFormSubmit} disabled={loading || prescriptionList.length === 0}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      {alert}
      {prescriptionList.length > 0 && (
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Prescription List</h5>
              </CardHeader>
              <CardBody>
                <ul>
                  {prescriptionList.map((prescription, index) => (
                    <li key={index}>
                      <strong>Disease:</strong> {prescription.dpDisease}<br />
                      <strong>Medicine:</strong> {prescription.dpMedicineName}<br />
                      <strong>Start Date:</strong> {prescription.dpStartDate}<br />
                      <strong>End Date:</strong> {prescription.dpEndDate}<br />
                      <strong>Schedule Time:</strong> {prescription.dpScheduleTime}<br />
                      <Button
                        color="info"
                        size="sm"
                        onClick={() => handleEditPrescription(index)}
                      >
                        Edit
                      </Button>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleDeletePrescription(index)}
                      >
                        Delete
                      </Button>
                    </li>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PrescriptionD;

import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  Button
} from "reactstrap";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 15];

const HospitalServices = () => {
  const navigate = useNavigate();
  const userProfile = JSON.parse(localStorage.getItem("userProfile")) || null;

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentServices, setCurrentServices] = useState([]);
  const [sortedServices, setSortedServices] = useState([]);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchText, setSearchText] = useState("");

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');
    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (userProfile && userProfile.heHId) {
      fetchData();
    }
  }, [userProfile?.heHId]);

  useEffect(() => {
    // Sort services when currentServices or sortOrder changes
    const sorted = [...currentServices].sort((a, b) => {
      const serviceA = a.hsServices.toLowerCase();
      const serviceB = b.hsServices.toLowerCase();
      if (sortOrder === "asc") {
        return serviceA.localeCompare(serviceB);
      } else {
        return serviceB.localeCompare(serviceA);
      }
    });
    setSortedServices(sorted);
  }, [currentServices, sortOrder]);

  const filteredServices = sortedServices.filter((service) =>
    service.hsServices.toLowerCase().includes(searchText.toLowerCase())
  );

  const indexOfLastService = currentPage * rowsPerPage;
  const indexOfFirstService = indexOfLastService - rowsPerPage;
  const servicesToDisplay = filteredServices.slice(
    indexOfFirstService,
    indexOfLastService
  );

  const fetchData = async () => {
    if (!userProfile || !userProfile.heHId) {
      return;
    }

    try {
      const response = await fetch(
        `https://lifelinecare.somee.com/api/HospitalServices/GetServicesByHospitalId/${userProfile.heHId}`
      );
      if (response.ok) {
        const data = await response.json();
        setCurrentServices(data);
        setError(null);
      } else if (response.status === 404) {
        setCurrentServices([]);
        setError("No services found for the hospital.");
      } else {
        console.error(
          "Failed to fetch hospital services:",
          response.status,
          response.statusText
        );
        setError("Failed to fetch hospital services.");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching hospital services:",
        error
      );
      setError("An error occurred while fetching hospital services.");
    }
  };

  const handleDelete = async (serviceId) => {
    const confirmation = await Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    });

    if (confirmation.isConfirmed) {
      try {
        const response = await fetch(`https://lifelinecare.somee.com/api/HospitalServices/${serviceId}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          Swal.fire(
            'Success',
            'Record deleted successfully!',
            'success'
          );
          fetchData(); // Call a function to refetch data or update state
        } else {
          Swal.fire(
            'Error deleting record',
            'Unknown error',
            'error'
          );
          console.error('Failed to delete service:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('An error occurred while deleting service:', error);
      }
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Input
            type="text"
            style={{ height: "70px", fontSize: "15px" }}
            placeholder="Search by service..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <br></br>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag="h3">Hospital Services</CardTitle>
                </Col>
                <Col className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      className="btn-icon"
                      color="link"
                      data-toggle="dropdown"
                      type="button"
                    >
                      <i className="tim-icons icon-settings-gear-63" />
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                      <DropdownItem onClick={toggleSortOrder}>
                        {sortOrder === "asc" ? "Sort By Z-A" : "Sort By A-Z"}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {error ? (
                <p>{error}</p>
              ) : filteredServices.length === 0 ? (
                <p>No results match the search.</p>
              ) : (
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Service</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {servicesToDisplay.map((service, index) => (
                      <tr key={index}>
                        <td>
                          <p>{service.hsServices}</p>
                        </td>
                        <td>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => handleDelete(service.hsId)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="pr-md-1" md="3">
                  <span>Rows Per Page:</span>
                  <select
                    className="form-control"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    {ROWS_PER_PAGE_OPTIONS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md="6" className="d-flex justify-content-center">
                  <Pagination style={{ marginTop: "40px" }}>
                    {Array.from(
                      {
                        length: Math.ceil(
                          filteredServices.length / rowsPerPage
                        ),
                      },
                      (_, i) => i + 1
                    ).map((number) => (
                      <PaginationItem
                        key={number}
                        active={number === currentPage}
                      >
                        <PaginationLink
                          onClick={() => paginate(number)}
                          href="#"
                        >
                          {number}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                  </Pagination>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HospitalServices;

import { faL } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table
} from "reactstrap";
import * as validator from 'validator';
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from 'react-router-dom';

export default function AddEmployeeH() {
  const navigate = useNavigate();

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  const userProfile = JSON.parse(localStorage.getItem('userProfile')) || null;
  const [formData, setFormData] = useState({
    HeName: "",
    HeRole: "",
    HeEmail: "",
    HePassword: "",
    HeHId: userProfile ? userProfile.hId : null,
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [alert, setAlert] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editingEmployeeId, setEditingEmployeeId] = useState(null);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await fetch(`https://lifelinecare.somee.com/api/HEmployee_Cr/${userProfile.hId}`);
      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data)) {
          setEmployees(data);
        } else {
          setEmployees([]); // If no employees found, set empty array
        }
      } else {
        console.error("Failed to fetch employees");
      }
    } catch (error) {
      console.error("Error:", error);
      showAlert("Error", "Error:", error, "error");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Setting max length to 40 for input fields
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.slice(0, 40),
    }));
  };

  const showAlert = (title, message, type) => {
    setAlert(
      <SweetAlert
        title={title}
        onConfirm={() => setAlert(null)}
        timeout={3000}
        type={type}
      >
        {message}
      </SweetAlert>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if any of the required fields are empty
    if (!formData.HeName || !formData.HeRole || !formData.HeEmail) {
      showAlert("Error", "Please fill in all the required fields!", "error");
      setLoading(false);
      return;
    }

    // Validate email format
    if (!validateEmail(formData.HeEmail)) {
      showAlert("Error", "Invalid or unusual email!", "error");
      setLoading(false);
      return;
    }

    try {
      const apiUrl = editingEmployeeId
        ? `https://lifelinecare.somee.com/api/HEmployee_Cr/${editingEmployeeId}`
        : "https://lifelinecare.somee.com/api/HEmployee_Cr";

      const method = editingEmployeeId ? "PUT" : "POST";

      const response = await fetch(apiUrl, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        showAlert(
          "Success",
          editingEmployeeId ? "Employee updated successfully!" : "Employee added successfully!",
          "success"
        );
        setTimeout(() => setSuccessMessage(null), 3000);
        setFormData((prevData) => ({
          ...prevData,
          HeName: "",
          HeRole: "",
          HeEmail: "",
        }));
        setEditingEmployeeId(null);
        fetchEmployees(); // Refresh the employee list after adding or updating
      } else {
        const errorMessage = await response.text(); // Get error message from API response
        showAlert("Error", errorMessage || "Error adding/updating employee", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showAlert("Error", "Error:", error, "error");
    } finally {
      setLoading(false); // Set loading to false when the submission is complete
    }
  };

  const validateEmail = (email) => {
    return validator.isEmail(email);
  };

  const handleEmailBlur = () => {
    const emailFieldValue = formData.HeEmail;

    if (!validateEmail(emailFieldValue)) {
      console.log('Invalid or unusual email');
    }
  };


  const handleDeleteEmployee = async (id) => {
    const confirmation = await Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this employee?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    });
  
    if (confirmation.isConfirmed) {
      try {
        const response = await fetch(`https://lifelinecare.somee.com/api/HEmployee_Cr/${id}`, {
          method: "DELETE",
        });
  
        if (response.ok) {
          Swal.fire(
            'Success',
            'Employee deleted successfully!',
            'success'
          );
          fetchEmployees(); // Refresh the employee list after deleting
        } else {
          const errorMessage = await response.text(); // Get error message from API response
          Swal.fire(
            'Error deleting employee',
            errorMessage || 'Unknown error',
            'error'
          );
        }
      } catch (error) {
        console.error("Error:", error);
        Swal.fire(
          'Error',
          `Error: ${error}`,
          'error'
        );
      }
    }
  };
        
  

  const handleEditEmployee = (employee) => {
    setFormData({
      HeName: employee.heName,
      HeRole: employee.heRole,
      HeEmail: employee.heEmail,
      HeHId: userProfile.hId,
    });
    setEditingEmployeeId(employee.heId);
  };

  const filteredEmployees = employees.filter(employee =>
    employee.heName && employee.heName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">{editingEmployeeId ? 'Edit Employee' : 'Add Employee'}</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Name</label>
                      <Input
                        defaultValue=""
                        value={formData.HeName}
                        name="HeName"
                        placeholder="Mike"
                        type="text"
                        required
                        maxLength={40}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Designation</label>
                      <Input
                        value={formData.HeRole}
                        defaultValue=""
                        name="HeRole"
                        placeholder="Employee"
                        type="text"
                        required
                        maxLength={15}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <Input
                        value={formData.HeEmail}
                        placeholder="mike@email.com"
                        name="HeEmail"
                        type="email"
                        onChange={handleInputChange}
                        onBlur={handleEmailBlur}
                        required
                        maxLength={40}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <CardFooter>
                  {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                  <Button
                    className="btn-fill"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Saving..." : editingEmployeeId ? "Update" : "Save"}
                  </Button>
                </CardFooter>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Employee List</h5>
              <Input
                type="text"
                placeholder="Search by Name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </CardHeader>
            <CardBody>
              {filteredEmployees.length === 0 ? (
                <p>No employee records found</p>
              ) : (
                <Table striped>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredEmployees.map(employee => (
                      <tr key={employee.heId}>
                        <td>{employee.heName}</td>
                        <td>{employee.heEmail}</td>
                        <td>{employee.heRole}</td>
                        <td>
                          <Button color="danger" size="sm" onClick={() => handleDeleteEmployee(employee.heId)}>Delete</Button>{' '}
                          <Button color="primary" size="sm" onClick={() => handleEditEmployee(employee)}>Edit</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, CardTitle, Table, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";

const PrescriptionsD = () => {
  const navigate = useNavigate(); // Add useNavigate hook
  const userProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
  const [prescriptions, setPrescriptions] = useState([]);
  const [filteredPrescriptions, setFilteredPrescriptions] = useState([]);
  const [searchPatientId, setSearchPatientId] = useState('');
  const [prescriptionDatas, setPrescriptionDatas] = useState(false);

  useEffect(() => {
    // Redirect to login page if userProfile is not available
    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate, userProfile]);

  useEffect(() => {
    const fetchPrescriptions = async () => {
      try {
        const response = await fetch(`https://lifelinecare.somee.com/api/medicine/prescription/${userProfile.dId}`);
        if (response.ok) {
          const data = await response.json();
          setPrescriptionDatas(data.length > 0);
          setPrescriptions(data || []);
        } else {
          console.error("Failed to fetch prescriptions:", response.status, response.statusText);
        }
      } catch (error) {
        console.error("An error occurred while fetching prescriptions:", error);
      }
    };

    fetchPrescriptions();
  }, [userProfile.dId]);

  useEffect(() => {
    // Filter prescriptions based on the entered patient ID
    const filtered = prescriptions.filter(prescription => prescription.dpPId.toString().includes(searchPatientId));
    setFilteredPrescriptions(filtered);
  }, [prescriptions, searchPatientId]);

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Prescription History</CardTitle>
          {/* Add input field for searching by patient ID */}
          <Input
            type="text"
            placeholder="Search by Patient ID"
            value={searchPatientId}
            onChange={(e) => setSearchPatientId(e.target.value)}
          />
        </CardHeader>

        <CardBody>
          {prescriptionDatas ? (
            <Table>
              <thead>
                <tr>
                  <th>Patient ID</th>
                  <th>Date</th>
                  <th>Disease</th>
                  <th>Medicine</th>
                  <th>Schedule Time</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredPrescriptions.map((prescription, index) => (
                  <tr key={index}>
                    <td>{prescription.dpPId}</td>
                    <td>{prescription.dpDate}</td>
                    <td>{prescription.dpDisease}</td>
                    <td>{prescription.dpMedicine}</td>
                    <td>{prescription.dpScheduleTime}</td>
                    <td>{prescription.dpStartDate}</td>
                    <td>{prescription.dpEndDate}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <h4>No history found.</h4>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default PrescriptionsD;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import * as validator from "validator";

export default function AddDoctorH() {
  const userProfile = JSON.parse(localStorage.getItem("userProfile")) || null;
  const navigate = useNavigate();
  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

   
    if (!userProfile) {
      
      navigate('/admin/Login');
    }
    
  }, [navigate]);
  const [formData, setFormData] = useState({
    DName: "",
    DHId: userProfile ? userProfile.hId : null,
    DField: "",
    DEmail: "",
    DPassword: "",
    DMobile: "",
    dAvailablityStatus: "",
    ImageFile: null,
    CheckinTime: "",
    CheckoutTime: "",
    AverageTimeToSeeOnePatient: "",
  });

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // If the input is for mobile number, remove any leading "+" character
    const processedValue = name === "DMobile" ? value.replace(/^\+/, '') : value;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: processedValue,
    }));
  };
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      ImageFile: file,
    }));
  };

  const showAlert = (title, message, type) => {
    setAlert(
      <SweetAlert
        title={title}
        onConfirm={() => setAlert(null)}
        timeout={3000}
        type={type}
      >
        {message}
      </SweetAlert>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if any of the required fields are empty
    if (
      !formData.DName ||
      !formData.DField ||
      !formData.DEmail ||
      !formData.DMobile ||
      !formData.dAvailablityStatus ||
      !formData.ImageFile ||
      !formData.CheckinTime ||
      !formData.CheckoutTime ||
      !formData.AverageTimeToSeeOnePatient
    ) {
      showAlert("Error", "Please fill in all the fields", "error");
      setLoading(false);
      return;
    }

    // Validate email format
    if (!validateEmail(formData.DEmail)) {
      showAlert("Error", "Invalid or unusual email!", "error");
      setLoading(false);
      return; // Skip form submission
    }

    const form = new FormData();
    for (const key in formData) {
      if (key === "ImageFile") {
        form.append("ImageFile", formData[key]);
      } else {
        form.append(key, formData[key]);
      }
    }

    try {
      const response = await fetch("https://lifelinecare.somee.com/api/Doctors_Cr", {
        method: "POST",
        body: form,
      });

      if (response.ok) {
        showAlert("Success", "Doctor added successfully!", "success");
        setTimeout(() => setSuccessMessage(null), 3000);
        setFormData({
          DName: "",
          DHId: userProfile ? userProfile.hId : null,
          DField: "",
          DEmail: "",
          DPassword: "",
          DMobile: "",
          dAvailablityStatus: "",
          ImageFile: null,
          CheckinTime: "",
          CheckoutTime: "",
          AverageTimeToSeeOnePatient: "",
        });
        // Redirect to the /admin/Doctor route
        navigate("/admin/DoctorH");
      } else if (response.status === 403) {
        // Show an error alert for 403 response
        alert(
          "Access denied. You do not have permission to perform this action."
        );
      } else {
        // Handle other error cases
        const responseData = await response.json();
        console.error("Server response:", responseData);

        // Check if the response indicates that a doctor with the email already exists
        if (
          responseData.error &&
          responseData.error.includes("Employee with this Email already exists")
        ) {
          showAlert(
            "Error",
            "Employee with this Email already exists!",
            "error"
          );
        }
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      // Add this line to log the stack trace
      console.error(error.stack);
    } finally {
      setLoading(false); // Set loading to false after form submission (whether successful or not)
    }
  };

  const validateEmail = (email) => {
    return validator.isEmail(email);
  };

  const handleEmailBlur = () => {
    const emailFieldValue = formData.DEmail;

    if (!validateEmail(emailFieldValue)) {
      console.log("Invalid or unusual email");
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Add Doctor</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Name</label>
                      <Input
                        value={formData.DName}
                        name="DName"
                        placeholder="Mike"
                        type="text"
                        onChange={handleInputChange}
                        required
                        minLength={3}
                        maxLength={25}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Field</label>
                      <Input
                        value={formData.DField}
                        name="DField"
                        placeholder="Surgeon"
                        type="text"
                        onChange={handleInputChange}
                        required
                        minLength={3}
                        maxLength={25}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <Input
                        value={formData.DEmail}
                        placeholder="mike@email.com"
                        name="DEmail"
                        type="email"
                        onChange={handleInputChange}
                        onBlur={handleEmailBlur}
                        required
                        minLength={5}
                        maxLength={50}
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Contact No.</label>
                      <Input
                        value={
                          formData.DMobile === "" ? "+92" : formData.DMobile
                        }
                        name="DMobile"
                        placeholder="Contact"
                        type="tel"
                        onChange={handleInputChange}
                        required
                        minLength={11}
                        maxLength={11}
                        pattern="\+92\d{10}"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Check-In Time</label>
                      <Input
                        value={formData.CheckinTime}
                        name="CheckinTime"
                        type="time"
                        onChange={handleInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <FormGroup>
                      <label>Check-Out Time</label>
                      <Input
                        value={formData.CheckoutTime}
                        name="CheckoutTime"
                        type="time"
                        onChange={handleInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="4">
                    <FormGroup>
                      <label>Average Time</label>
                      <select
                        className="form-control"
                        name="AverageTimeToSeeOnePatient"
                        value={formData.AverageTimeToSeeOnePatient}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Average Time</option>
                        <option value="00:10:00">10 minutes</option>
                        <option value="00:15:00">15 minutes</option>
                        <option value="00:20:00">20 minutes</option>
                        <option value="00:30:00">30 minutes</option>
                        <option value="00:45:00">45 minutes</option>
                        <option value="01:00:00">60 minutes</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Availability Status</label>
                      <select
                        className="form-control"
                        name="dAvailablityStatus"
                        id="genderSelect"
                        value={formData.dAvailablityStatus}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="Available">Available</option>
                        <option value="Not Available">Not Available</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Add Photo</label>
                      <Input
                        type="file"
                        name="ImageFile"
                        id="Photo"
                        onChange={handleFileChange}
                        required
                      />

                      <br></br>
                      <div
                        style={{
                          border: "2px solid #00bf9a",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "50px",
                          height: "50px",
                        }}
                      >
                        <i className="fa fa-camera" />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                {successMessage && (
                  <p style={{ color: "green" }}>{successMessage}</p>
                )}
                <Button
                  className="btn-fill"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert}
    </div>
  );
}

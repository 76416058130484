import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Input,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const ReviewH = () => {
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));

    if (!userProfile) {
      navigate('/admin/Login');
      return;
    }

    const fetchReviews = async () => {
      try {
        const response = await fetch(`https://lifelinecare.somee.com/api/Feedback_Cr/Getreview?Hid=${userProfile.hId}`);
        if (response.ok) {
          const data = await response.json();
          setReviews(data);
          setFilteredReviews(data);
          setLoading(false);
        } else {
          console.error('Failed to fetch reviews:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('An error occurred while fetching reviews:', error);
      }
    };

    fetchReviews();
  }, [navigate]);

  useEffect(() => {
    const filtered = reviews.filter(review =>
      review.doctorName.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredReviews(filtered);
  }, [searchText, reviews]);

  const generateStars = (rating, index) => {
    const stars = [];
    for (let i = 5; i >= 1; i--) {
      stars.push(
        <React.Fragment key={i}>
          <input readOnly disabled
            type="radio"
            id={`star${i}-${index}-${rating}`}
            name={`rating-${index}-${rating}`}
            value={i}
            className="visuallyhidden"
            defaultChecked={i === rating}
          />
          <label htmlFor={`star${i}-${index}-${rating}`}>★</label>
        </React.Fragment>
      );
    }
    return stars;
  };

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-tasks">
            <CardHeader>
              <h6 className="title d-inline">Review</h6>
            </CardHeader>
            <CardBody>
              <Input
                type="text"
                placeholder="Search by doctor name..."
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />
              <div className="table-full-width table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th>By</th>
                      <th>Comments</th>
                      <th>Doctor Name</th>
                      <th>Doctor Email</th>
                      <th>Rating</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredReviews.length === 0 && !loading ? (
                      <tr>
                        <td colSpan="5" className="text-center">No records available</td>
                      </tr>
                    ) : (
                      filteredReviews.map((review, index) => (
                        <tr key={index}>
                          <td>{review.patientName}</td>
                          <td>{review.comments}</td>
                          <td>{review.doctorName}</td>
                          <td>{review.doctorEmail}</td>
                          <td>
                            <div className="product-review-stars" style={{ width: "70px" }}>
                              {generateStars(review.ratings, index)}
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ReviewH;

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from 'sweetalert2';
import * as validator from 'validator';
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Loader } from "@googlemaps/js-api-loader";

const libraries = ["places"];
let searchBox;

const loader = new Loader({
  apiKey: "AIzaSyATSwW04E178O9zGmyS19JCSloeMUe1dpc",
  version: "weekly",
});

export default function AddHospitalS() {
  const navigate = useNavigate(); // Create a navigate function
  const [loading, setLoading] = useState(false); // Add loading state
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  const showAlert = (title, message, type, error = null) => {
    let errorMessage = message;
    if (error) {
      errorMessage += `\n${error}`;
    }
    setAlert(
      <SweetAlert
        title={title}
        onConfirm={() => setAlert(null)}
        timeout={3000}
        type={type}
      >
        {errorMessage}
      </SweetAlert>
    );
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);

      // Validate email before submission
      if (!validateEmail(values.hEmail)) {
        showAlert("Error", "Invalid or unusual email", "error");
        return; // Skip form submission
      }

      // Include the selectedLocation data in the form submission
      values.hlLatitude = selectedLocation.lat;
      values.hlLongitude = selectedLocation.lng;

      // Make a POST request to the API to add the hospital
      const response = await fetch('https://lifelinecare.somee.com/api/Hospital_Cr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        showAlert("Success", "Hospital Added Successfully", "success");
        resetForm();
        navigate('/super/HospitalS', { replace: true });
        // Wait for a short period to ensure the navigation happens before reloading
        setTimeout(() => {
          window.location.reload();
        }, 1); // Adjust the timeout as needed

      } else {
        const responseData = await response.json();
        const errorMessage = responseData.error || `Failed to add hospital: ${response.statusText}`;
        showAlert("Error", errorMessage, "error");
      }
    } catch (error) {
      showAlert("Error", "Error during form submission", "error", error.stack);
      console.error(error.stack);
    } finally {
      setLoading(false); // Set loading to false after form submission (whether successful or not)
    }
  };

  const validateEmail = (email) => {
    return validator.isEmail(email);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: checkoutSchema,
    onSubmit: handleFormSubmit,
  });

  const { values, handleSubmit, handleChange, handleBlur } = formik;

  const handleEmailBlur = () => {
    const emailFieldValue = values.hEmail;

    if (!validateEmail(emailFieldValue)) {
      console.log('Invalid or unusual email');
    }
  };

  const handlePlaceSelect = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setSelectedLocation({ lat, lng });
    const address = place.formatted_address || "";
    formik.setFieldValue("hlLatitude", lat);
    formik.setFieldValue("hlLongitude", lng);
    formik.setFieldValue("hAddress", address);
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Add Hospital</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className="pr-md-1" md="12">
                    <FormGroup>
                      <label>Name</label>
                      <Input
                        defaultValue=""
                        name="hName"
                        placeholder=""
                        type="text"
                        maxLength={70}
                        required
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.hName}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        placeholder="example@email.com"
                        name="hEmail"
                        type="email"
                        onBlur={handleEmailBlur}
                        required
                        maxLength={100}
                        onChange={handleChange}
                        value={values.hEmail}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Address</label>
                      <Input
                        defaultValue=""
                        name="hAddress"
                        placeholder="Search & select location from map below"
                        type="text"
                        onBlur={handleBlur}
                        required
                        onChange={handleChange}
                        value={values.hAddress || ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Longitude</label>
                      <Input
                        defaultValue=""
                        name="hlLongitude"
                        placeholder="Search & select location from map below"
                        type="text"
                        required
                        readOnly
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.hlLongitude || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Latitude</label>
                      <Input
                        defaultValue=""
                        name="hlLatitude"
                        placeholder="Search & select location from map below"
                        type="text"
                        required
                        readOnly
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.hlLatitude || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <CardFooter>
                  <Button className="btn-fill" color="success" type="submit">
                    {loading ? 'Adding Hospital...' : 'Add Hospital'}
                  </Button>
                </CardFooter>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <h4 style={{textAlign:"center"}}>Search & select location from map below to populate above fields automatically</h4>
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              width: "calc(100% - 20px)",
              marginBottom: "10px",
            }}
          >
            <LoadScript
              googleMapsApiKey={loader.apiKey}
              libraries={libraries}
            >
              <StandaloneSearchBox
                onLoad={(ref) => (searchBox = ref)}
                onPlacesChanged={() => {
                  handlePlaceSelect(searchBox.getPlaces()[0]);
                }}
              >
                <input
                  type="text"
                  placeholder="Search for a location"
                  style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `100%`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    marginTop: "20px",
                  }}
                />
              </StandaloneSearchBox>
            </LoadScript>
          </div>
          <br />
          <LoadScript
            googleMapsApiKey={loader.apiKey}
            libraries={libraries}
          >
            <GoogleMap
              mapContainerStyle={{
                height: "400px",
                width: "100%",
                marginTop: "60px",
              }}
              center={selectedLocation}
              zoom={15}
            >
              {selectedLocation && <Marker position={selectedLocation} />}
            </GoogleMap>
          </LoadScript>
        </Col>
      </Row>
      {alert}
    </div>
  );

}

const checkoutSchema = yup.object().shape({
  hName: yup.string().required("required"),
  hEmail: yup.string().email("invalid email").required("required"),
  hlLatitude: yup.string().required("required"),
  hlLongitude: yup.string().required("required"),
  hAddress: yup.string().required("required"),
});

const initialValues = {
  hName: "",
  hEmail: "",
  hlLatitude: "",
  hlLongitude: "",
  hAddress: "",
};

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import Swal from 'sweetalert2';

export default function CreateAppoint() {
  const navigate = useNavigate();

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

    if (!userProfile) {
      navigate('/admin/Login');
    }
    
  }, [navigate]);

  const userProfile = JSON.parse(localStorage.getItem("userProfile")) || null;

  const initialFormData = {
    APatientName: userProfile?.pName || "",
    APatientDOB: userProfile?.pDob || '2024-04-25T09:00:00',
    ADate: "",
    ATime: "",
    ADId: "",
    AType: "",
    AMobile: userProfile?.pMobile || "",
    AEmail: userProfile?.pEmail || "",
    AReason: "",
  };

  const [doctors, setDoctors] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [availableTimes, setAvailableTimes] = useState([]);

  const fetchDoctors = async (dhId) => {
    try {
      const response = await fetch(`https://lifelinecare.somee.com/api/Doctors_Cr/ByHospitalId/${dhId}`);
      if (response.ok) {
        const doctorList = await response.json();
        setDoctors(doctorList);
      } else {
        console.error("Failed to fetch doctors:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred while fetching doctors:", error);
    }
  };

  const fetchAvailableTimes = async (doctorId, date) => {
    try {
      const response = await fetch(`https://lifelinecare.somee.com/api/Doctors_Cr/returntime?DoctorId=${doctorId}&AppointmentDate=${date}`);
      if (response.ok) {
        const times = await response.json();
        const formattedTimes = times.map(time => {
          const formattedTime = new Date(time);
          return formattedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        });
        setAvailableTimes(formattedTimes);
      } else {
        console.error("Failed to fetch available times:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred while fetching available times:", error);
    }
  };

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const response = await fetch("https://lifelinecare.somee.com/api/Hospital_Cr");
        if (response.ok) {
          const hospitalList = await response.json();
          setHospitals(hospitalList);
        } else {
          console.error("Failed to fetch hospitals:", response.status, response.statusText);
        }
      } catch (error) {
        console.error("An error occurred while fetching hospitals:", error);
      }
    };

    fetchHospitals();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log("Form Data:", formData);
      const selectedTime = formData.ATime;
      const [hours, minutes, period] = selectedTime.split(/:| /);
      const hourInt = parseInt(hours);
      const minuteInt = parseInt(minutes);
      let time24Hours = "";
      if (period === "PM" && hourInt !== 12) {
        time24Hours = `${hourInt + 12}:${minutes}:00.0000000`;
      } else if (period === "AM" && hourInt === 12) {
        time24Hours = `00:${minutes}:00.0000000`;
      } else {
        time24Hours = `${hourInt}:${minutes}:00.0000000`;
      }
      const updatedFormData = { ...formData, ATime: time24Hours };

      const response = await fetch("https://lifelinecare.somee.com/api/appointment_cr", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedFormData),
      });

      if (response.ok) {
        setFormData(initialFormData);
        Swal.fire('Success', "You will get an email once it's approved!", 'success');
        navigate("/patient/Appointment");
      } else {
        Swal.fire('Error', "Error in taking appointment", 'error');
        console.error("Failed to create appointment:", response.status, response.statusText);
      }
    } catch (error) {
      Swal.fire('Error', "Error in taking appointment", 'error');
      console.error("An error occurred during form submission:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === "AHId") {
      setDoctors([]);
      fetchDoctors(value);
      setFormData((prevFormData) => ({ ...prevFormData, ADId: "", ADate: "" }));
      setAvailableTimes([]);
    } else if (name === "ADId") {
      setFormData((prevFormData) => ({ ...prevFormData, ADate: "" }));
      setAvailableTimes([]);
    } else if (name === "ADate") {
      if (formData.ADId) {
        fetchAvailableTimes(formData.ADId, value);
      }
    }
  };

  const today = new Date();
  const todayString = today.toISOString().split("T")[0];

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Create Appointment</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Select Hospital</label>
                      <select
                        className="form-control"
                        name="AHId"
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Hospital</option>
                        {hospitals.map((hospital) => (
                          <option key={hospital.hId} value={hospital.hId}>
                            {hospital.hName}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Select Doctor</label>
                      <select
                        className="form-control"
                        name="ADId"
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Doctor</option>
                        {doctors.map((doctor) => (
                          <option key={doctor.dId} value={doctor.dId}>
                            {doctor.dName}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Select Appointment Type</label>
                      <select
                        className="form-control"
                        name="AType"
                        id="genderSelect"
                        value={formData.AType}
                        required
                        onChange={handleInputChange}
                      >
                        <option value="">Select Type</option>
                        <option value="pa">Physical Appointment</option>
                        <option value="vca">Video Consultation Appointment</option>
                        <option value="other">Other</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Select Appointment Date</label>
                      <Input
                        defaultValue=""
                        name="ADate"
                        placeholder="Select Date"
                        type="date"
                        required
                        onChange={handleInputChange}
                        min={new Date().toISOString().split('T')[0]}
                      />
                    </FormGroup>
                  </Col>
                  {formData.ADate && (
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Select Appointment Time</label>
                        <select
  className="form-control"
  name="ATime"
  required
  onChange={(e) =>
    setFormData((prevFormData) => ({
      ...prevFormData,
      ATime: e.target.value,
    }))
  }
>
  {availableTimes.length > 0 ? (
    // Map over availableTimes only if it's not empty
    <>
      <option value="">Select Time</option>
      {availableTimes.map((time, index) => (
        <option key={index} value={time}>
          {time}
        </option>
      ))}
    </>
  ) : (
    <option value="" disabled>
      Not available
    </option>
  )}
</select>


                      </FormGroup>
                    </Col>
                  )}
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Appointment Reason</label>
                      <Input
                        defaultValue=""
                        name="AReason"
                        placeholder="Reason For Booking Appointment"
                        type="textarea"
                        required
                        onChange={handleInputChange}
                        minLength={10}
                        maxLength={250}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  className="btn-fill"
                  color="success"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const userProfile = JSON.parse(localStorage.getItem("userProfile")) || null;

const EditableInput = ({ defaultValue, type, field, patientId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    console.log("Updated value:", inputValue);

    // Make sure userProfile is defined before making the API call
    if (userProfile === undefined || userProfile === null) {
      console.error("Invalid userProfile:", userProfile);
      Swal.fire('Error', 'Invalid user profile', 'error');
      return;
    }

    // Create an object to store only the updated field
    const updatedField = { [field]: inputValue };

    // Make an API call to update the profile
    try {
      const response = await fetch(`https://lifelinecare.somee.com/api/patient_cr/${patientId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedField), // Send only the updated field
      });

      if (response.ok) {
        const updatedProfile = { ...userProfile, ...updatedField }; // Merge the updated field into the userProfile
        localStorage.setItem("userProfile", JSON.stringify(updatedProfile));
        Swal.fire('Success', 'Profile updated successfully', 'success');
      } else {
        console.error("Failed to update profile");
        Swal.fire('Error', 'Failed to update profile', 'error');
      }
    } catch (error) {
      console.error("Error during profile update:", error);
      Swal.fire('Error', 'An error occurred during profile update', 'error');
    }
  };

  return (
    <>
      {isEditing ? (
        <input
          type={type}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleSaveClick}
        />
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {type === "password" ? "********" : inputValue}
          <FontAwesomeIcon
            icon={faPencilAlt}
            className="ml-2"
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
      {isEditing && (
        <Button className="mt-2" color="primary" onClick={handleSaveClick}>
          Save
        </Button>
      )}
    </>
  );
};

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

    if (!userProfile) {
      navigate('/admin/Login');
    } else {
      setLoading(false);
    }
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content">
      <Row>
        <Col md="4">
          <Card className="card-user">
            <CardBody>
              <div className="author">
                <div className="block block-one" />
                <div className="block block-two" />
                <div className="block block-three" />
                <div className="block block-four" />
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <br></br>
                  <h5 className="title">
                    {userProfile !== null ? userProfile.pId : "Loading..."}
                  </h5>
                </a>
                <p className="description">Patient</p>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Edit Profile</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label htmlFor="exampleInputName1">Change Name</label>
                      <EditableInput
                        defaultValue={
                          userProfile !== null ? userProfile.pName : ""
                        }
                        type="text"
                        field="pName"
                        patientId={userProfile !== null ? userProfile.pId : ""}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Change Password</label>
                      <EditableInput
                        defaultValue={
                          userProfile !== null ? userProfile.pPassword : ""
                        }
                        type="password"
                        field="pPassword"
                        patientId={userProfile !== null ? userProfile.pId : ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Change Contact No.</label>
                      <EditableInput
                        defaultValue={
                          userProfile !== null ? userProfile.pMobile : ""
                        }
                        field="pMobile"
                        patientId={userProfile !== null ? userProfile.pId : ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <CardFooter>
              <br></br>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;

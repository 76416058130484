import React, { useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from 'sweetalert2';

const ROWS_PER_PAGE_OPTIONS = [10, 15];

export default function HospitalS() {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [hospitalsData, setHospitalsData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [searchHospitalName, setSearchHospitalName] = useState('');
  const navigate = useNavigate();
  
  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);


  const showAlert = (title, message, type) => {
    setAlert(
      <SweetAlert
        title={title}
        onConfirm={() => setAlert(null)}
        timeout={3000}
        type={type}
      >
        {message}
      </SweetAlert>
    );
  };

  const fetchHospitals = async () => {
    try {
      const response = await fetch('https://lifelinecare.somee.com/api/Hospital_Cr');
      if (response.ok) {
        const data = await response.json();
        setHospitalsData(data);
      } else {
        console.error('Failed to fetch hospitals data:', response.status, response.statusText);
        showAlert("Error", `Failed to fetch hospitals data: ${response.status} ${response.statusText}`, "error");
      }
    } catch (error) {
      console.error('Error during data fetching:', error);
      showAlert("Error", `Error during data fetching: ${error.message}`, "error");
    }
  };

  useEffect(() => {
    const isFirstLoad = localStorage.getItem('isFirstLoad');
    if (!isFirstLoad) {
      localStorage.setItem('isFirstLoad', true);
      window.location.reload();
    } else {
      fetchHospitals();
      localStorage.removeItem('isFirstLoad');
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const indexOfLastHospital = currentPage * rowsPerPage;
  const indexOfFirstHospital = indexOfLastHospital - rowsPerPage;
  const currentHospital = hospitalsData.slice(
    indexOfFirstHospital,
    indexOfLastHospital
  );

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (index) => {
    setEditMode(true);
    setEditIndex(index);
  };

  const saveEditedHospital = async () => {
    try {
      const editedHospital = currentHospital[editIndex];

      if (editedHospital && editedHospital.hId !== undefined) {
        const response = await fetch(`https://lifelinecare.somee.com/api/Hospital_Cr/${editedHospital.hId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(editedHospital),
        });

        if (response.ok) {
          showAlert("Success", "Updated Successfully", "success");
          fetchHospitals();
        } else {
          showAlert("Error", `Failed to save edited hospital: ${response.status} ${response.statusText}`, "error");
        }
      } else {
        showAlert("Error", `Invalid hospital data for editing: ${editedHospital}`, "error");
      }
    } catch (error) {
      console.error('Error during saveEditedHospital:', error);
      showAlert("Error", `Error during saveEditedHospital: ${error.message}`, "error");
    } finally {
      setEditMode(false);
      setEditIndex(null);
    }
  };

  const deleteHospital = async (index) => {
    try {
      const hospitalToDelete = currentHospital[index];

      const confirmReject = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this hospital?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
      });

      if (!confirmReject.isConfirmed) {
        return;
      }

      const response = await fetch(`https://lifelinecare.somee.com/api/Hospital_Cr/${hospitalToDelete.hId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        showAlert("Success", "Hospital Deleted Successfully", "success");
        fetchHospitals();
      } else {
        showAlert("Error", `Failed to delete hospital: ${response.status} ${response.statusText}`, "error");
      }
    } catch (error) {
      console.error('Error during deleteHospital:', error);
      showAlert("Error", `Error during deleteHospital: ${error.message}`, "error");
    }
  };

  const filterHospitalsByName = (name) => {
    return hospitalsData.filter(hospital => hospital.hName.toLowerCase().includes(name.toLowerCase()));
  };

  const filteredHospitals = searchHospitalName ? filterHospitalsByName(searchHospitalName) : currentHospital;

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Link to="/super/AddHospitalS">
            <Button color="success">Add Hospital</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <br></br>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Hospitals</CardTitle>
              <Input
                type="text"
                placeholder="Search by Hospital Name"
                value={searchHospitalName}
                onChange={(e) => setSearchHospitalName(e.target.value)}
              />
            </CardHeader>
            <CardBody>
              {filteredHospitals.length === 0 ? (
                <p>No results found</p>
              ) : (
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Email</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredHospitals.map((hospital, index) => (
                      <tr key={index}>
                        <td>{hospital.hId}</td>
                        <td>
                          {editMode &&
                            editIndex === indexOfFirstHospital + index ? (
                            <input
                              type="text"
                              value={hospital.hName}
                              onChange={(e) => {
                                const updatedHospitals = [...hospitalsData];
                                updatedHospitals[
                                  indexOfFirstHospital + index
                                ].hName = e.target.value;
                                setHospitalsData(updatedHospitals);
                              }}
                            />
                          ) : (
                              hospital.hName
                            )}
                        </td>
                        <td>
                          {editMode &&
                            editIndex === indexOfFirstHospital + index ? (
                              <input
                                type="text"
                                value={hospital.hAddress}
                                onChange={(e) => {
                                  const updatedHospitals = [...hospitalsData];
                                  updatedHospitals[
                                    indexOfFirstHospital + index
                                  ].hAddress = e.target.value;
                                  setHospitalsData(updatedHospitals);
                                }}
                              />
                            ) : (
                                hospital.hAddress
                              )}
                          </td>
                          <td>
                            {editMode &&
                              editIndex === indexOfFirstHospital + index ? (
                              <input
                                type="text"
                                value={hospital.hEmail}
                                onChange={(e) => {
                                  const updatedHospitals = [...hospitalsData];
                                  updatedHospitals[
                                    indexOfFirstHospital + index
                                  ].hEmail = e.target.value;
                                  setHospitalsData(updatedHospitals);
                                }}
                              />
                            ) : (
                                hospital.hEmail
                              )}
                          </td>
                          <td>{hospital.hlLatitude}</td>
                          <td>{hospital.hlLongitude}</td>
                          <td>
                            {editMode &&
                              editIndex === indexOfFirstHospital + index ? (
                              <button
                                className="btn btn-success"
                                onClick={saveEditedHospital}
                              >
                                Save
                              </button>
                            ) : (
                                <button
                                  className="btn btn-warning"
                                  onClick={() =>
                                    handleEdit(indexOfFirstHospital + index)
                                  }
                                >
                                  Edit
                                </button>
                              )}
                          </td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => deleteHospital(index)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  )}
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col className="pr-md-1" md="3">
                      <span>Rows Per Page:</span>
                      <select
                        className="form-control"
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                      >
                        {ROWS_PER_PAGE_OPTIONS.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md="6" className="d-flex justify-content-center">
                      <Pagination style={{ marginTop: "40px" }}>
                        {Array.from(
                          {
                            length: Math.ceil(hospitalsData.length / rowsPerPage),
                          },
                          (_, i) => i + 1
                        ).map((number) => (
                          <PaginationItem
                            key={number}
                            active={number === currentPage}
                          >
                            <PaginationLink
                              onClick={() => paginate(number)}
                              href="#"
                            >
                              {number}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                      </Pagination>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {alert}
        </div>
      );
    }

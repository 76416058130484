import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Input,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 15];

export default function AppointmentE() {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [appointments, setAppointments] = useState([]);
  const userProfile = JSON.parse(localStorage.getItem("userProfile")) || null;
  const [appointmentsData, setAppointmentsData] = useState(false);
  const [alert, setAlert] = useState(null);
  const [doctorSearchTerm, setDoctorSearchTerm] = useState("");

  const indexOfLastPatient = currentPage * rowsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - rowsPerPage;
  const currentPatients = appointments.slice(
    indexOfFirstPatient,
    indexOfLastPatient
  );

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const showAlert = (title, message, type) => {
    setAlert(
      <SweetAlert
        title={title}
        onConfirm={() => setAlert(null)}
        timeout={3000}
        type={type}
      >
        {message}
      </SweetAlert>
    );
  };

  const handleAcceptAppointment = async (aId) => {
    try {
      const response = await fetch(
        `https://lifelinecare.somee.com/api/patient_cr/Accept("${aId}")`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ aId: aId }),
        }
      );

      if (response.ok) {
        showAlert("Success", "Accepted Succesfully!", "success");
        fetchData();
      } else {
        showAlert(
          "Error",
          "An error occured while accepting appointment",
          "error"
        );
      }
    } catch (error) {
      showAlert(
        "Error",
        "An error occured while accepting appointment",
        error,
        "error"
      );
    }
  };

  const handleRejectAppointment = async (aId) => {
    // Display a confirmation dialog using SweetAlert2
    const confirmReject = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to reject this appointment?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, reject it!',
      cancelButtonText: 'No, keep it',
    });
  
    if (confirmReject.isConfirmed) {
      try {
        const response = await fetch(`https://lifelinecare.somee.com/api/appointment_cr/${aId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Appointment rejected successfully.',
          });
  
          // Refresh the data
          fetchData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Failed to reject appointment: ${response.status} ${response.statusText}`,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `An error occurred while rejecting appointment: ${error.message}`,
        });
      }
    }
  };

  // Add a check to ensure appointment.ad?.dName is not null
  const filteredAppointments = appointments.filter((appointment) =>
    appointment.ad?.dName?.toLowerCase().includes(doctorSearchTerm.toLowerCase())
  );

  // Get current appointments to display after filtering and pagination
  const currentAppointments = filteredAppointments.slice(
    indexOfFirstPatient,
    indexOfLastPatient
  );

  const fetchData = async () => {
    if (!userProfile || !userProfile.heHId) {
      return;
    }

    try {
      const response = await fetch(
        `https://lifelinecare.somee.com/api/appointment_cr/appointments?hospitalId=${userProfile.heHId}`
      );
      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data)) {
          setAppointmentsData(data.length > 0);
          setAppointments(data);
        }
      } else {
        console.error(
          "Failed to fetch appointments:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred while fetching appointments:", error);
    }
  };

  const formatDate = (dateString) => {
    try {
      const formattedDate = new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };

  useEffect(() => {
    fetchData(); // This will run only once when the component mounts
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Appointments</CardTitle>
            </CardHeader>
            <Input
              type="text"
              placeholder="Search by Doctor Name"
              value={doctorSearchTerm}
              onChange={(e) => setDoctorSearchTerm(e.target.value)}
            />
            <CardBody>
              {filteredAppointments.length > 0 ? (
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Patient Name</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Reason</th>
                      <th>D.No.</th>
                      <th>Doctor Name</th>
                      <th>Accept</th>
                      <th>Reject</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAppointments.map((appointment, index) => (
                      <tr key={index}>
                        <td>
                          <p>{appointment.aPatientName}</p>
                        </td>
                        <td>
                          <p>{appointment.aType}</p>
                        </td>
                        <td>
                          <p>{formatDate(appointment.aDate)}</p>
                        </td>
                        <td>
                          <p>{appointment.aTime}</p>
                        </td>
                        <td>
                          <p>{appointment.aReason}</p>
                        </td>
                        <td>
                          <p>{appointment.adId}</p>
                        </td>
                        <td>
                          <p>{appointment.ad?.dName}</p>
                        </td>
                        <td>
                          <button
                            className="btn btn-success"
                            onClick={() =>
                              handleAcceptAppointment(appointment.aId)
                            }
                          >
                            Accept
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-warning"
                            onClick={() =>
                              handleRejectAppointment(appointment.aId)
                            }
                          >
                            Reject
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h4>No appointments match your search.</h4>
              )}
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="pr-md-1" md="3">
                  <span>Rows Per Page:</span>
                  <select
                    className="form-control"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    {ROWS_PER_PAGE_OPTIONS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md="6" className="d-flex justify-content-center">
                  <Pagination style={{ marginTop: "40px" }}>
                    {Array.from(
                      {
                        length: Math.ceil(appointments.length / rowsPerPage),
                      },
                      (_, i) => i + 1
                    ).map((number) => (
                      <PaginationItem
                        key={number}
                        active={number === currentPage}
                      >
                        <PaginationLink
                          onClick={() => paginate(number)}
                          href="#"
                        >
                          {number}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                  </Pagination>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      {alert}
    </div>
  );
}

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import "./../assets/css/login.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is included

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setShowError(true);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch("https://lifelinecare.somee.com/api/Admin_Cr/Login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        const { role_id, userProfile } = data;
        localStorage.setItem("userProfile", JSON.stringify(userProfile));

        switch (role_id) {
          case 1:
            navigate("/super/HospitalS", { state: { userProfile } });
            break;
          case 2:
            navigate("/admin/DashH", { state: { userProfile } });
            break;
          case 3:
            navigate("/employee/AppointmentE", { state: { userProfile } });
            break;
          case 4:
            navigate("/doctor/NotificationD", { state: { userProfile } });
            break;
          case 5:
            navigate("/patient/Appointment", { state: { userProfile } });
            break;
          default:
            break;
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Invalid Credentials. Please Try Again.',
        });
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 2000);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Server Error. Try again later',
      });
      setServerError(true);

      setTimeout(() => {
        setServerError(false);
      }, 3000);

      console.error("Error during login:", error);
    } finally {
      setLoading(false); // Set loading to false when request completes
    }
  };
  setTimeout(() => {
    setShowError(false);
  }, 4000);

  return (
    <div className="container">
      <div className="screen">
        <div className="screen__content">
          <form className="login" onSubmit={handleLogin}>
            <div className="text center">
              <h2 className="XYZ">LIFELINE</h2>
            </div>
            <div className="login__field">
              <i className="login__icon fas fa-user"></i>
              <input
                type="email"
                className="login__input"
                placeholder="User name / Email"
                value={email}
                required
                name="email"
                maxLength={30}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="login__field">
              <i className="login__icon fas fa-lock"></i>
              <input
                type="password"
                className="login__input"
                placeholder="Password"
                name="pswd"
                required
                value={password}
                maxLength={25}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              className="button login__submit"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="spinner-border text-primary" role="status" style={{ marginRight: '8px' }}>
                    <span className="sr-only">Loading...</span>
                  </div>
                  <span>Loading...</span>
                </div>
              ) : (
                <>
                  <span className="button__text">Login</span>
                  <i className="button__icon fas fa-chevron-right"></i>
                </>
              )}
            </button>
          </form>
          <div className="social-login">
            <div className="social-icons"></div>
          </div>
          {showError && (
            <p
              style={{
                color: "red",
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "'Raleway', 'sans-serif'",
              }}
            >
              Invalid Email Format. Please Enter Correct Email.
            </p>
          )}
          {serverError && (
            <p
              style={{
                color: "red",
                textAlign: "center",
                fontWeight: "normal",
                fontSize: '30px !important',
                fontFamily: "'Raleway', 'sans-serif'",
              }}
            >
              Server Error. Try again later
            </p>
          )}
          <p style={{ textAlign: "center" }}>
            <Link
              to="/admin/Forgot"
              className="forgot_password_login"
              style={{
                color: "#FFF",
                fontWeight: "bold",
                fontFamily: "Verdana, Geneva, sans-serif",
              }}
            >
              Forgot Password?
            </Link>
          </p>
        </div>
        <div className="screen__background">
          <span className="screen__background__shape screen__background__shape4"></span>
          <span className="screen__background__shape screen__background__shape3"></span>
          <span className="screen__background__shape screen__background__shape2"></span>
          <span className="screen__background__shape screen__background__shape1"></span>
        </div>
      </div>
    </div>
  );
}

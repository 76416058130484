import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Input,
} from "reactstrap";

const ROWS_PER_PAGE_OPTIONS = [5, 10, 15];

const Appointment = () => {
  const navigate = useNavigate();
  const userProfile = JSON.parse(localStorage.getItem('userProfile')) || null;

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [appointmentsData, setAppointmentsData] = useState(false);
  const [searchDate, setSearchDate] = useState("");

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

    if (!userProfile) {
      navigate('/admin/Login');
      return;
    }
    
    const fetchData = async () => {
      try {
        if (userProfile) {
          const response = await fetch(`https://lifelinecare.somee.com/api/appointment_cr/AppointmentHistory?a_email=${JSON.parse(userProfile).pEmail}`);
          if (response.ok) {
            const appointmentData = await response.json();
            const filteredData = appointmentData.filter(appointment => new Date(appointment.aDate) >= new Date());
            setAppointmentsData(filteredData.length > 0);
            setAppointments(filteredData);
            setFilteredAppointments(filteredData);
          } else {
            console.error("Failed to fetch appointments:", response.status, response.statusText);
          }
        }
      } catch (error) {
        console.error("An error occurred while fetching appointments:", error);
      }
    };

    if (userProfile) {
      fetchData();
    }
  }, [navigate]);

  const indexOfLastAppointment = currentPage * rowsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - rowsPerPage;
  const currentAppointment = filteredAppointments.slice(
    indexOfFirstAppointment,
    indexOfLastAppointment
  );

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCancelAppointment = async (aId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!'
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(`https://lifelinecare.somee.com/api/appointment_cr/${aId}`, {
          method: "DELETE",
        });

        if (response.ok) {
          setAppointments((prevAppointments) =>
            prevAppointments.filter((appointment) => appointment.aId !== aId)
          );
          setFilteredAppointments((prevAppointments) =>
            prevAppointments.filter((appointment) => appointment.aId !== aId)
          );
          Swal.fire(
            'Cancelled!',
            'Your appointment has been cancelled.',
            'success'
          );
        } else {
          console.error("Failed to cancel appointment:", response.status, response.statusText);
        }
      } catch (error) {
        console.error("An error occurred while canceling appointment:", error);
      }
    }
  };

  const handleSearchDateChange = (e) => {
    setSearchDate(e.target.value);
    const searchFiltered = appointments.filter(appointment => 
      appointment.aDate.startsWith(e.target.value)
    );
    setFilteredAppointments(searchFiltered);
    setCurrentPage(1);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const formatTime = (timeStr) => {
    const [hour, minute] = timeStr.split(':');
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minute} ${ampm}`;
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Current Appointments</CardTitle>
              <Input
                type="date"
                value={searchDate}
                onChange={handleSearchDateChange}
                placeholder="Search by date"
              />
            </CardHeader>
            <CardBody>
              {filteredAppointments.length > 0 ? (
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Type</th>
                      <th>Reason</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAppointment.map((appointment, index) => (
                      <tr key={index}>
                        <td>
                          <p>{formatDate(appointment.aDate)}</p>
                        </td>
                        <td>
                          <p>{formatTime(appointment.aTime)}</p>
                        </td>
                        <td>
                          <p>{appointment.aType}</p>
                        </td>
                        <td>
                          <p>{appointment.aReason}</p>
                        </td>
                        <td>
                          <button
                            className="btn btn-warning"
                            onClick={() => handleCancelAppointment(appointment.aId)}
                          >
                            Cancel
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h4>No new appointments found.</h4>
              )}
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="pr-md-1" md="3">
                  <span>Rows Per Page:</span>
                  <select
                    className="form-control"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    {ROWS_PER_PAGE_OPTIONS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md="6" className="d-flex justify-content-center">
                  <Pagination style={{ marginTop: "40px" }}>
                    {Array.from(
                      {
                        length: Math.ceil(filteredAppointments.length / rowsPerPage),
                      },
                      (_, i) => i + 1
                    ).map((number) => (
                      <PaginationItem
                        key={number}
                        active={number === currentPage}
                      >
                        <PaginationLink
                          onClick={() => paginate(number)}
                          href="#"
                        >
                          {number}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                  </Pagination>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Appointment;

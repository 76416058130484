import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import "./../assets/css/star.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

export default function FeedbackP() {
  const navigate = useNavigate();
  const userProfile = JSON.parse(localStorage.getItem('userProfile')) || null;

  useEffect(() => {
    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate, userProfile]);

  const initialFormData = {
    APatientName: userProfile ? userProfile.pName : "",
    APatientDOB: userProfile ? userProfile.pDob : "",
    ADate: "",
    ATime: "",
    ADId: "",
    AType: "",
    AMobile: userProfile ? userProfile.pMobile : "",
    AEmail: userProfile ? userProfile.pEmail : "",
    AReason: "",
    F_Comments: "", // Added F_Comments field to the formData state
  };
  const [formData, setFormData] = useState(initialFormData);

  const [rating, setRating] = useState(0); // New state for handling user's selected rating
  const [doctors, setDoctors] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState(null);
  const fetchDoctors = async (dhId) => {
    try {
      const response = await fetch(`https://lifelinecare.somee.com/api/Doctors_Cr/ByHospitalId/${dhId}`);
      if (response.ok) {
        const doctorList = await response.json();
        setDoctors(doctorList);
      } else {
        console.error("Failed to fetch doctors:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred while fetching doctors:", error);
    }
  };

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const response = await fetch("https://lifelinecare.somee.com/api/Hospital_Cr");
        if (response.ok) {
          const hospitalList = await response.json();
          setHospitals(hospitalList);
        } else {
          console.error("Failed to fetch hospitals:", response.status, response.statusText);
        }
      } catch (error) {
        console.error("An error occurred while fetching hospitals:", error);
      }
    };

    fetchHospitals();
  }, []);

  const showAlert = (title, message, type) => {
    setAlert(
      <SweetAlert
        title={title}
        onConfirm={() => {
          setAlert(null);
          clearFormData();
        }}
        timeout={3000}
        type={type}
      >
        {message}
      </SweetAlert>
    );
  };

  const clearFormData = () => {
    setFormData({
      ...initialFormData,
      AHId: ""
    });
    setRating(0); // Clear the rating
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await fetch("https://lifelinecare.somee.com/api/Feedback_Cr", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fId: 0,
          fdId: formData.ADId,
          fpId: userProfile.pId,
          fRating: rating,
          fComments: formData.F_Comments,
        }),
      });

      if (response.ok) {
        setLoading(false);
        showAlert("Success", "Thanks for your feedback!", "success");
      } else {
        showAlert("Error", "Failed to submit feedback. Please try again.Fields Required", "error");
      }
    } catch (error) {
      showAlert("Error", "An error occurred during form submission.", "error");
      setLoading(false);
    }
  };

  const handleRatingChange = (e) => {
    setRating(parseInt(e.target.value, 10));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === "AHId") {
      setDoctors([]);
      fetchDoctors(value);
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Feedback</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Select Hospital</label>
                      <select
                        className="form-control"
                        name="AHId"
                        onChange={handleInputChange}
                        value={formData.AHId} // Add value attribute
                      >
                        <option value="">Select Hospital</option>
                        {hospitals.map((hospital) => (
                          <option key={hospital.hId} value={hospital.hId}>
                            {hospital.hName}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Select Doctor</label>
                      <select
                        className="form-control"
                        name="ADId"
                        onChange={handleInputChange}
                        value={formData.ADId} // Add value attribute
                      >
                        <option value="">Select Doctor</option>
                        {doctors.map((doctor) => (
                          <option key={doctor.dId} value={doctor.dId}>
                            {doctor.dName}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Feedback</label>
                      <Input
                        defaultValue=""
                        name="F_Comments"
                        required
                        maxLength={300}
                        placeholder="Comments"
                        type="textarea"
                        onChange={handleInputChange}
                        value={formData.F_Comments} // Add value attribute
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <label>Rating</label>
                    <div className="product-review-stars">
                      {[5, 4, 3, 2, 1].map((star) => (
                        <React.Fragment key={star}>
                          <input
                            type="radio"
                            id={`star${star}`}
                            name="rating"
                            value={star}
                            className="visuallyhidden"
                            onChange={handleRatingChange}
                            checked={rating === star} // Add checked attribute
                          />
                          <label htmlFor={`star${star}`} style={{ fontSize: '2em' }}>★</label>
                        </React.Fragment>
                      ))}
                    </div>
                  </Col>
                </Row>
                <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit">
                    Save
                  </Button>
                </CardFooter>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert}
    </div>
  );
}

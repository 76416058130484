import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Table,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

const NotificationD = () => {
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);
  const [notificationData, setNotificationData] = useState(false);
  const [searchPatientName, setSearchPatientName] = useState('');
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [patientsPerPage] = useState(5);

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');
    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const userProfile = JSON.parse(localStorage.getItem('userProfile')) || null;
        const pdId = userProfile ? userProfile.dId : null;
        const apiUrl = `https://lifelinecare.somee.com/api/patient_cr/doctorpatient?pdId=${pdId}`;
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (Array.isArray(data)) {
          setNotificationData(data.length > 0);
          setPatients(data);
          setFilteredPatients(data);
        } else {
          setNotificationData(data.length > 0);
          setPatients([data]);
          setFilteredPatients([data]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPatients();
  }, []);

  const formatTime = (timeString) => {
    try {
      const [hours, minutes] = timeString.split(":");
      const formattedTime = new Date(0, 0, 0, hours, minutes).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
      });
      return formattedTime;
    } catch (error) {
      console.error("Error formatting time:", error);
      return "Invalid Time";
    }
  };

  const formatDate = (dateString) => {
    try {
      const formattedDate = new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchPatientName(searchValue);
    const filtered = patients.filter(patient =>
      patient.pName.toLowerCase().includes(searchValue)
    );
    setFilteredPatients(filtered);
  };

  // Pagination logic
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients = filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">New Appointments</CardTitle>
              <Input
                type="text"
                placeholder="Search by Patient Name"
                value={searchPatientName}
                onChange={handleSearchChange}
              />
            </CardHeader>
            <Table className="tablesorter" responsive>
              <thead className="text-primary">
                <tr>
                  <th>Patient Name</th>
                  <th>Patient No.</th>
                  <th>Appointment Time</th>
                  <th>Appointment Date</th>
                  <th>Mobile No.</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {currentPatients.length > 0 ? (
                  currentPatients.map((patient, index) => {
                    const today = new Date();
                    const appointmentDate = new Date(patient.pDate);
                    const timeDifference = today.getTime() - appointmentDate.getTime();
                    const daysDifference = timeDifference / (1000 * 3600 * 24);

                    if (daysDifference <= 1) {
                      return (
                        <tr key={index}>
                          <td>{patient.pName}</td>
                          <td>{patient.pId}</td>
                          <td style={{ color: 'red' }}>{formatTime(patient.pTime)}</td>
                          <td style={{ color: 'red' }}>{formatDate(patient.pDate)}</td>
                          <td>{patient.pMobile}</td>
                          <td>{patient.pEmail}</td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: 'center' }}>No new Appointments</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination>
              {Array.from({ length: Math.ceil(filteredPatients.length / patientsPerPage) }, (_, i) => (
                <PaginationItem key={i + 1} active={i + 1 === currentPage}>
                  <PaginationLink onClick={() => paginate(i + 1)} href="#">
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
            </Pagination>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default NotificationD;

import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, FormGroup, Form, Input, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';

const ProfileH = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    setUserProfile(userProfile);
    if (!userProfile) {
      navigate('/admin/Login');
    } else {
      setLoading(false);
    }
  }, [navigate]);

  const handleFieldUpdate = async (field, value) => {
    try {
      const updatedUserProfile = { ...userProfile, [field]: value };
      const response = await fetch(`https://lifelinecare.somee.com/api/Hospital_Cr/${userProfile.hId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUserProfile),
      });

      if (response.ok) {
        setUserProfile(updatedUserProfile);
        if(field === 'hPassword') {
          Swal.fire({
            icon: 'success',
            title: 'Password Updated Successfully!',
            showConfirmButton: false,
            timer: 1500
          });
        }
      } else {
        console.error('Failed to update profile');
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to update profile!',
        });
      }
    } catch (error) {
      console.error('Error during profile update:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred!',
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <CardTitle tag="h2">Profile</CardTitle>
                </Col>
                <Col xs="12">
                  <h2>{userProfile ? userProfile.hName : "Loading..."}</h2>
                </Col>
              </Row>
            </CardHeader>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="4">
          <Card className="card-user">
            <CardBody>
              <div className="author">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <h5 className="title">{userProfile ? userProfile.hId : "Loading..."}</h5>
                </a>
                <p className="description">Admin</p>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Edit Profile</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label htmlFor="exampleInputName1">Change Name</label>
                      <EditableInput
                        defaultValue={userProfile ? userProfile.hName : ""}
                        type="text"
                        field="hName"
                        hospitalId={userProfile ? userProfile.hId : ""}
                        onSave={handleFieldUpdate}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Change Address</label>
                      <EditableInput
                        defaultValue={userProfile ? userProfile.hAddress : ""}
                        type="text"
                        field="hAddress"
                        onSave={handleFieldUpdate}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label htmlFor="exampleInputEmail1">Change Email</label>
                      <EditableInput
                        defaultValue={userProfile ? userProfile.hEmail : ""}
                        type="email"
                        field="hEmail"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Change Password</label>
                      <EditablePasswordInput
                        field="hPassword"
                        onSave={handleFieldUpdate}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Change Latitude</label>
                      <EditableInput
                        defaultValue={userProfile ? userProfile.hlLatitude : ""}
                        type="text"
                        field="hlLatitude"
                        onSave={handleFieldUpdate}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Change Longitude</label>
                      <EditableInput
                        defaultValue={userProfile ? userProfile.hlLongitude : ""}
                        type="text"
                        field="hlLongitude"
                        onSave={handleFieldUpdate}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const EditableInput = ({ defaultValue, type, field, hospitalId, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onSave(field, inputValue);
  };

  return (
    <>
      {isEditing ? (
        <>
          <Input
            type={type}
            value={inputValue}
            onChange={handleInputChange}
          />
          {type !== 'email' && (
            <Button color="primary" onClick={handleSaveClick}>Save</Button>
          )}
        </>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input value={inputValue} disabled />
          {type !== 'email' && (
            <FontAwesomeIcon
              icon={faPencilAlt}
              className="ml-2"
              onClick={handleEditClick}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      )}
    </>
  );
};

const EditablePasswordInput = ({ field, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onSave(field, inputValue);
    setInputValue("");
  };

  return (
    <>
      {isEditing ? (
        <>
          <Input
            type="password"
            value={inputValue}
            onChange={handleInputChange}
          />
          <Button color="primary" onClick={handleSaveClick}>Save</Button>
        </>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input value="********" disabled />
          <FontAwesomeIcon
            icon={faPencilAlt}
            className="ml-2"
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
    </>
  );
};

export default ProfileH;

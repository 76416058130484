import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from 'react-router-dom';

const userProfile = JSON.parse(localStorage.getItem("userProfile")) || null;

export default function AddBloodE() {
  const navigate = useNavigate();
  
  const [bloodAvailability, setBloodAvailability] = useState({
    baHId: userProfile ? userProfile.heHId : null,
    baBloodGroup: "",
    baBottlesAvailable: "",
    baDate: "",
    baTime: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "baBottlesAvailable") {
      // Ensure that the value is always positive and not less than 1
      newValue = Math.max(1, parseInt(value) || 1);
    } else if (name === "baTime") {
      // Format the time value to HH:mm:ss format
      const timeArray = value + ":00";
      newValue = timeArray;
    }

    setBloodAvailability({ ...bloodAvailability, [name]: newValue });
  };

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);

  const showAlert = (title, message, type) => {
    setAlert(
      <SweetAlert
        title={title}
        onConfirm={() => setAlert(null)}
        timeout={3000}
        type={type}
      >
        {message}
      </SweetAlert>
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await fetch(
        "https://lifelinecare.somee.com/api/BloodAvailability_Cr",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bloodAvailability),
        }
      );

      if (response.ok) {
        showAlert("Success", "Inserted successfully!", "success");
      } else {
        showAlert(
          "Error",
          `Failed to add blood availability: ${response.status} ${response.statusText}`,
          "error"
        );
      }
    } catch (error) {
      showAlert(
        "Error",
        "An error occurred while adding blood availability:",
        error,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const bloodGroups = ["A+", "B+", "AB+", "O+", "A-", "B-", "AB-", "O-"];

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');
    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Add Blood Form</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleFormSubmit}>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Blood Group</label>
                      <select
                        className="form-control"
                        name="baBloodGroup"
                        value={bloodAvailability.baBloodGroup}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Blood Group</option>
                        {bloodGroups.map((group) => (
                          <option key={group} value={group}>
                            {group}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1" md="4">
                    <FormGroup>
                      <label>Bottles Donated</label>
                      <Input
                        name="baBottlesAvailable"
                        type="number"
                        required
                        value={bloodAvailability.baBottlesAvailable}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="px-md-1" md="4">
                    <label>Select Date</label>
                    <Input
                      name="baDate"
                      type="date"
                      required
                      value={bloodAvailability.baDate}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col className="pl-md-1" md="4">
                    <FormGroup>
                      <label>Select Time</label>
                      <Input
                        className="form-control"
                        name="baTime"
                        value={bloodAvailability.baTime}
                        onChange={handleInputChange}
                        required
                        type="time"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button className="btn-fill" color="success" type="submit">
                  {loading ? "Saving..." : "Save"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

const ROWS_PER_PAGE_OPTIONS = [5, 10, 15];

const PrescriptionHistory = () => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [prescriptions, setPrescriptions] = useState([]);
  const [prescriptionsData, setPrescriptionsData] = useState(false);
  const [searchDisease, setSearchDisease] = useState('');
  const navigate = useNavigate();
  const userProfile = JSON.parse(localStorage.getItem('userProfile')) || null;

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');
    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  // Function to fetch prescription history
  const fetchPrescriptionHistory = async () => {
    try {
      const response = await fetch(`https://lifelinecare.somee.com/api/medicine/${userProfile.pId}`);
      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data)) {
          setPrescriptionsData(data.length > 0);
          setPrescriptions(data);
        } else {
          console.error("Invalid data format:", data);
        }
      } else {
        console.error("Failed to fetch prescriptions:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("An error occurred while fetching prescriptions:", error);
    }
  };

  // Use useEffect to fetch prescription history on page render
  useEffect(() => {
    fetchPrescriptionHistory();
  }, [userProfile.pId]);

  // Function to filter prescriptions based on the entered disease
  const filteredPrescriptions = prescriptions.filter(prescription =>
    prescription.dpDisease.toLowerCase().includes(searchDisease.toLowerCase())
  );

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "2-digit" };
    return date.toLocaleDateString("en-US", options).replace(/(\d+)(st|nd|rd|th)/, '$1');
  };

  // Pagination
  const indexOfLastPrescription = currentPage * rowsPerPage;
  const indexOfFirstPrescription = indexOfLastPrescription - rowsPerPage;
  const currentPrescriptions = filteredPrescriptions.slice(
    indexOfFirstPrescription,
    indexOfLastPrescription
  );

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Prescription History</CardTitle>
              <Input
                type="text"
                placeholder="Search by Disease"
                value={searchDisease}
                onChange={(e) => setSearchDisease(e.target.value)}
                style={{ width: '300px', marginLeft: '10px' }}
              />
            </CardHeader>
            <CardBody>
              {prescriptionsData ? (
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Disease</th>
                      <th>Date</th>
                      <th>Medicines</th>
                      <th>Schedule Time</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPrescriptions.map((prescription, index) => (
                      <tr key={index}>
                        <td>
                          <p>{prescription.dpDisease}</p>
                        </td>
                        <td>
                          <p>{formatDate(prescription.dpDate)}</p>
                        </td>
                        <td>
                          <p>{prescription.dpMedicine.split('\n').join(', ')}</p>
                        </td>
                        <td>
                          <p>{prescription.dpScheduleTime}</p>
                        </td>
                        <td>
                          <p>{formatDate(prescription.dpStartDate)}</p>
                        </td>
                        <td>
                          <p>{formatDate(prescription.dpEndDate)}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <h4>No new prescriptions found.</h4>
              )}
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="pr-md-1" md="3">
                  <span>Rows Per Page:</span>
                  <select
                    className="form-control"
                    value={rowsPerPage}
                    onChange={(e) => setRowsPerPage(parseInt(e.target.value))}
                  >
                    {ROWS_PER_PAGE_OPTIONS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md="6" className="d-flex justify-content-center">
                  <Pagination style={{ marginTop: "40px" }}>
                    {Array.from(
                      {
                        length: Math.ceil(filteredPrescriptions.length / rowsPerPage),
                      },
                      (_, i) => i + 1
                    ).map((number) => (
                      <PaginationItem
                        key={number}
                        active={number === currentPage}
                      >
                        <PaginationLink
                          onClick={() => setCurrentPage(number)}
                          href="#"
                        >
                          {number}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                  </Pagination>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PrescriptionHistory;

import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardTitle, Row, Col, Input, Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export default function ViewPatientH() {
  const [patients, setPatients] = useState([]);
  const [searchPatient, setSearchPatient] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [patientsPerPage] = useState(5);
  const userProfile = JSON.parse(localStorage.getItem('userProfile')) || null;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPatients = async () => {
      if (!userProfile || !userProfile.hId) {
        console.error("Invalid userProfile or hospitalId");
        return;
      }

      try {
        const response = await fetch(`https://lifelinecare.somee.com/api/patient_cr/patients?hospitalId=${userProfile.hId}`);
        const data = await response.json();
        setPatients(data);
      } catch (error) {
        console.error("Error fetching patients:", error);
      }
    };

    fetchPatients();
  }, [userProfile]);

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  const filteredPatients = patients.filter(patient =>
    patient.pName.toLowerCase().includes(searchPatient.toLowerCase()) ||
    (patient.pd && patient.pd.dName.toLowerCase().includes(searchPatient.toLowerCase()))
  );

  // Get current patients for pagination
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients = filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="content">
      <Row>
        <Col xs="12">
          <div className="d-flex justify-content-between mb-3">
            <Input
              type="text"
              placeholder="Search by Patient Name"
              value={searchPatient}
              onChange={(e) => setSearchPatient(e.target.value)}
              className="form-control"
            />
          </div>
          <Card className="card-chart">
            <CardHeader>
              <CardTitle tag="h2">Patients</CardTitle>
            </CardHeader>
            <div className="table-responsive">
              <Table className="table-striped table-bordered">
                <thead>
                  <tr>
                    <th><FontAwesomeIcon icon={faUser} /></th>
                    <th>Patient Name</th>
                    <th>Age</th>
                    <th>Patient ID</th>
                    <th>Join Date</th>
                    <th>Doctor ID</th>
                    <th>Doctor Name</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPatients.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">No records available</td>
                    </tr>
                  ) : (
                    currentPatients.map((patient) => (
                      <tr key={patient.pId}>
                        <td className="text-center">
                          <FontAwesomeIcon icon={faUser} size="2x" />
                        </td>
                        <td>{patient.pName}</td>
                        <td>{calculateAge(patient.pDob)}</td>
                        <td>#{patient.pId}</td>
                        <td>{formatDate(patient.pDate)}</td>
                        <td>{patient.pdId || "Not specified"}</td>
                        <td>{patient.pd ? patient.pd.dName || "Not specified" : "Not specified"}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
            <div style={{ overflowX: 'auto' }}>
              <Pagination aria-label="Page navigation" className="d-flex justify-content-center mt-3">
                <PaginationItem disabled={currentPage <= 1}>
                  <PaginationLink
                    onClick={() => paginate(currentPage - 1)}
                    previous
                    href="#"
                  />
                </PaginationItem>
                {Array.from({ length: Math.ceil(filteredPatients.length / patientsPerPage) }, (_, i) => (
                  <PaginationItem active={i + 1 === currentPage} key={i}>
                    <PaginationLink onClick={() => paginate(i + 1)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage >= Math.ceil(filteredPatients.length / patientsPerPage)}>
                  <PaginationLink
                    onClick={() => paginate(currentPage + 1)}
                    next
                    href="#"
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

function calculateAge(dateOfBirth) {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age === 0 ? 1 : age;
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
}

import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Pagination, PaginationItem, PaginationLink, CardFooter, Input } from "reactstrap";
import { Link} from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 15]; // Add this line

const PatientD = () => {
  const navigate = useNavigate(); // Add useNavigate hook
  const [patients, setPatients] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchPatientName, setSearchPatientName] = useState('');
  const [userProfile, setUserProfile] = useState(null); // Define userProfile state

  useEffect(() => {
    // Redirect to login page if userProfile is not available
    const profile = JSON.parse(localStorage.getItem('userProfile'));
    if (!profile) {
      navigate('/admin/Login');
    } else {
      setUserProfile(profile);
    }
  }, [navigate]);

  useEffect(() => {
    if (userProfile) {
      const fetchPatients = async () => {
        try {
          const pdId = userProfile.dId;
          const apiUrl = `https://lifelinecare.somee.com/api/patient_cr/doctorpatient?pdId=${pdId}`;
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (Array.isArray(data)) {
            setPatients(data);
          } else {
            setPatients([data]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchPatients();
    }
  }, [userProfile]);

  const indexOfLastPatient = currentPage * rowsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - rowsPerPage;
  const currentPatients = patients.slice(indexOfFirstPatient, indexOfLastPatient);

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filterPatientsByName = (name) => {
    return patients.filter(patient => patient.pName.toLowerCase().includes(name.toLowerCase()));
  };

  const formatTime = (timeString) => {
    const date = new Date(`2000-01-01T${timeString}`);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const filteredPatients = searchPatientName ? filterPatientsByName(searchPatientName) : currentPatients;

  // Function to check if the appointment time is within 1 to 15 minutes range
  const isWithinRange = (timeString) => {
    const dateString = new Date().toLocaleDateString(); // Get today's date in a format like "MM/DD/YYYY"
    const dateTimeString = `${dateString} ${timeString}`; // Concatenate date and time string

    const appointmentTime = new Date(dateTimeString);
    const currentTime = new Date();

    const differenceInMinutes = Math.floor((appointmentTime - currentTime) / (1000 * 60));

    return differenceInMinutes >= 0 && differenceInMinutes <= 15;
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Patients</CardTitle>
              <Input
                type="text"
                placeholder="Search by Patient Name"
                value={searchPatientName}
                onChange={(e) => setSearchPatientName(e.target.value)}
              />
            </CardHeader>
            <CardBody>
              {filteredPatients.length > 0 ? (
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>DOB</th>
                      <th>Contact</th>
                      <th>Email</th>
                      <th>Appointment Date</th>
                      <th>Appointment Time</th>
                      <th>Prescription</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPatients.map((patient, index) => (
                      <tr key={index}>
                        <td>
                          <p>{patient.pName}</p>
                        </td>
                        <td>
                          <p>{new Date(patient.pDob).toLocaleDateString()}</p>
                        </td>
                        <td>
                          <p>{patient.pMobile}</p>
                        </td>
                        <td>
                          <p>{patient.pEmail}</p>
                        </td>
                        <td>
                          <p>{new Date(patient.pDate).toLocaleDateString()}</p>
                        </td>
                        <td>
                          <p>{formatTime(patient.pTime)}</p>
                        </td>
                        <td>
                          {patient.paStatus && patient.paStatus.includes('online') ? (
                            !isWithinRange(patient.pTime) ? (
                              <div>
                                <button className="btn btn-success" disabled={!isWithinRange(patient.pTime)}>
                                  Start Meeting
                                </button>
                              </div>
                            ) : (
                              <Link to={`/doctor/CallButton/${patient.pEmail}/${userProfile.dEmail}`}>
                                <button className="btn btn-success">
                                  Start Meeting
                                </button>
                              </Link>
                            )
                          ) : (
                            <Link to={`/doctor/PrescriptionD/${patient.pId}`}>
                              <button className="btn btn-success">Add</button>
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>No records found.</p>
              )}
            </CardBody>
            {filteredPatients.length > 0 && (
              <CardFooter>
                <Row>
                  <Col className="pr-md-1" md="3">
                    <span>Rows Per Page:</span>
                    <select
                      className="form-control"
                      value={rowsPerPage}
                      onChange={handleRowsPerPageChange}
                    >
                      {ROWS_PER_PAGE_OPTIONS.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col md="6" className="d-flex justify-content-center">
                    <Pagination style={{ marginTop: "40px" }}>
                      {Array.from(
                        {
                          length: Math.ceil(patients.length / rowsPerPage),
                        },
                        (_, i) => i + 1
                      ).map((number) => (
                        <PaginationItem
                          key={number}
                          active={number === currentPage}
                        >
                          <PaginationLink
                            onClick={() => paginate(number)}
                            href="#"
                          >
                            {number}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                    </Pagination>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PatientD;

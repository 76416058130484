import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from 'react-router-dom';

const VideoChat = () => {
    const navigate = useNavigate();
    const [meetingUrl, setMeetingUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(null); // State to manage the alert
    const { pem, dem } = useParams();

    useEffect(() => {
        const userProfile = localStorage.getItem('userProfile');
        if (!userProfile) {
            navigate('/admin/Login');
        } else {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        if (!loading) {
            const script = document.createElement('script');
            script.src = 'https://meet.jit.si/external_api.js';
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                console.log('Jitsi Meet External API script loaded');
                handleStartMeeting();
            };

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [loading]);

    const handleStartMeeting = async () => {
        if (window.JitsiMeetExternalAPI) {
            const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const stringLength = 30;

            const pickRandom = () => {
                return possible[Math.floor(Math.random() * possible.length)];
            };

            const randomString = Array.apply(null, Array(stringLength)).map(pickRandom).join('');

            const domain = "meet.jit.si";
            const options = {
                "roomName": randomString,
                "width": 600,
                "height": 600,
                "userInfo": {
                    "email": dem
                },
                "invitedUsers": pem
            };

            const meetingUrl = `https://meet.jit.si/${randomString}`;
            setMeetingUrl(meetingUrl);

            const payload = {
                email: pem,
                doctor: dem,
                link: meetingUrl
            };

            try {
                const response = await axios.post('https://lifelinecare.somee.com/api/patient_cr/Sendvideoemail', payload);
                console.log('API request successful:', response.data);
                setAlert(
                    <SweetAlert
                        success
                        title="Success"
                        onConfirm={() => setAlert(null)}
                    >
                        The meeting link has been sent successfully!
                    </SweetAlert>
                );
            } catch (error) {
                console.error('Error sending API request:', error);
                setAlert(
                    <SweetAlert
                        danger
                        title="Error"
                        onConfirm={() => setAlert(null)}
                    >
                        Failed to send the meeting link. Please try again.
                    </SweetAlert>
                );
            }
        } else {
            console.error('Jitsi Meet External API script not loaded');
        }
    };

    useEffect(() => {
        if (meetingUrl) {
            window.open(meetingUrl, '_blank');
        }
    }, [meetingUrl]);

    return (
        <div className='container p-5 m-5'>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <p>Starting video chat...</p>
            )}
            {alert}
        </div>
    );
};

export default VideoChat;

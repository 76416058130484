import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from 'react-router-dom';

export default function ServicesE() {
  const navigate = useNavigate();
  const userProfile = JSON.parse(localStorage.getItem("userProfile")) || {};
  const hospitalId = userProfile.heHId || null;

  const [services, setServices] = useState([{ id: 1, value: "" }]);
  const [serviceCount, setServiceCount] = useState(1);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const userProfile = localStorage.getItem('userProfile');

    if (!userProfile) {
      navigate('/admin/Login');
    }
  }, [navigate]);

  const handleServiceChange = (id, event) => {
    const updatedServices = services.map((service) =>
      service.id === id ? { ...service, value: event.target.value } : service
    );
    setServices(updatedServices);
  };

  const showAlert = (title, message, type) => {
    setAlert(
      <SweetAlert
        title={title}
        onConfirm={() => setAlert(null)}
        timeout={3000}
        type={type}
      >
        {message}
      </SweetAlert>
    );
  };

  const handleSave = async () => {
    try {
      const hasEmptyService = services.some(
        (service) => !service.value || service.value.trim() === ""
      );

      if (hasEmptyService) {
        showAlert("Error", "Add a service first!", "error");
        return;
      }

      const response = await fetch(
        "https://lifelinecare.somee.com/api/HospitalServices",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            hsHId: hospitalId,
            hsServices: services.map((service) => service.value).join(", "), // Convert array to string
          }),
        }
      );

      if (response.ok) {
        showAlert("Success", "Service added successfully!", "success");
        // Reset services to contain only one empty service
        setServices([{ id: 1, value: "" }]);
        setServiceCount(1); // Reset service count to 1
      } else {
        // Handle error, maybe show an error message
        console.error("Failed to add services");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">Add Service</h5>
            </CardHeader>
            <CardBody>
              <Form>
                {services.map((service) => (
                  <Row key={service.id}>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Service</label>
                        <Input
                          value={service.value}
                          name={`H_Service_${service.id}`}
                          placeholder="Service"
                          type="text"
                          required
                          onChange={(event) =>
                            handleServiceChange(service.id, event)
                          }
                          minLength={5}
                          maxLength={25}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                ))}
                <Button
                  className="btn-fill"
                  color="success"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert}
    </div>
  );
}
